import React, { Component } from 'react';
import logo from '../logo.svg';
import '../App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { SliderPicker, ChromePicker } from 'react-color'

import Switch from '@material-ui/core/Switch';

import Header from '../components/Header';


import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const API = 'https://api.dibit.co.il/v1/';

class EditIconsset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iconssetId: '',
      name: '',
      whatsappIcon: '',
      wazeIcon: '',
      facebookIcon: '',
      instagramIcon: '',
      websiteIcon: '',
      youtubeIcon: '',
      shopIcon: '',
      callmeIcon: '',
      portfolioIcon: '',
      iconsset: {
        color: '',
        whatsappIcon: '',
        wazeIcon: '',
        facebookIcon: '',
        instagramIcon: '',
        websiteIcon: '',
        youtubeIcon: '',
        shopIcon: '',
        callmeIcon: '',
        portfolioIcon: '',
      }
    };
  }

  componentDidMount () {
    this.getIconsSet();
  }

  changeCanUploadBackgroundImage = () => {
    let canUploadBackgroundImage = this.state.canUploadBackgroundImage ? false : true;

    this.setState({canUploadBackgroundImage: canUploadBackgroundImage});
  }


  getIconsSet = () => {
    let url = window.location.href;
    let name = 'id';
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    this.setState({iconssetId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
      try {
        let token = cookies.get('__dato__');

        axios.get(API + 'admin/iconsset?id='+this.state.iconssetId, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then(response => {
          console.log(response);
          if (response.data.status === "ok") {
            this.setState({
              color: response.data.data.iconsset.color,
              whatsappIcon: response.data.data.iconsset.whatsappIcon,
              wazeIcon: response.data.data.iconsset.wazeIcon,
              facebookIcon: response.data.data.iconsset.facebookIcon,
              instagramIcon: response.data.data.iconsset.instagramIcon,
              websiteIcon: response.data.data.iconsset.websiteIcon,
              youtubeIcon: response.data.data.iconsset.youtubeIcon,
              shopIcon: response.data.data.iconsset.shopIcon,
              callmeIcon: response.data.data.iconsset.callmeIcon,
              portfolioIcon: response.data.data.iconsset.portfolioIcon,
              iconsset: response.data.data.iconsset,
            });
          } else {
            alert('חלה שגיאה בקבלת הנתונים, אנא נסו שוב');
          }
        }).catch(err => {
          console.log(err);
          alert('חלה שגיאה בקבלת הנתונים, אנא נסו שוב');
        });
      } catch (err) {
        console.log(err);
        alert('חלה שגיאה בקבלת הנתונים, אנא נסו שוב');
      }
    });
  }


  updateIconsSet = () => {
    try {
      let token = cookies.get('__dato__');

      let payload = {
        color: this.state.color,
      }

      axios.put(API + 'admin/iconsset?id=' + this.state.iconssetId, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          alert('סט האייקונים עודכנה בהצלחה');
        } else {
          alert('חלה שגיאה, אנא נסה בשנית');
        }
      }).catch(err => {
        console.log(err);
        alert('חלה שגיאה, אנא נסה בשנית');
      });
    } catch (err) {
      console.log(err);
      alert('חלה שגיאה, אנא נסה בשנית');
    }
  }

  onFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file',this.state.file);
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'admin/template/cover?id='+this.state.iconssetId,formData,config)
          .then((response) => {
            // window.location.reload();
            this.getIconsSet();
          }).catch((error) => {
      });
    }
  }
  onFormSubmitDefaultCover = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file',this.state.fileDefaultCover);
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'admin/template/default-cover?id='+this.state.iconssetId,formData,config)
          .then((response) => {
            // window.location.reload();
            this.getIconsSet();
          }).catch((error) => {
            console.log(error);
      });
    }
  }
  onFormSubmitBackgroundImage = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file',this.state.fileBackgroundImage);
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'admin/template/background-image?id='+this.state.iconssetId,formData,config)
          .then((response) => {
            // window.location.reload();
            this.getIconsSet();
          }).catch((error) => {
            console.log(error);
      });
    }
  }

  onFormSubmitWhatsapp = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file',this.state.fileWhatsapp);
    formData.append('color',this.state.color);
    formData.append('icon','whatsappIcon');
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'admin/iconsset/icon',formData,config)
          .then((response) => {
            // window.location.reload();
            this.getIconsSet();
          }).catch((error) => {
      });
    }
  }
  onChangeWhatsapp = (e) => {
    this.setState({fileWhatsapp:e.target.files[0]});
  }

  onFormSubmitPortfolio = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file',this.state.filePortfolio);
    formData.append('color',this.state.color);
    formData.append('icon','portfolioIcon');
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'admin/iconsset/icon',formData,config)
          .then((response) => {
            // window.location.reload();
            this.getIconsSet();
          }).catch((error) => {
      });
    }
  }
  onChangePortfolio = (e) => {
    this.setState({filePortfolio:e.target.files[0]});
  }

  onFormSubmitCallme = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file',this.state.fileCallme);
    formData.append('color',this.state.color);
    formData.append('icon','callmeIcon');
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'admin/iconsset/icon',formData,config)
          .then((response) => {
            // window.location.reload();
            this.getIconsSet();
          }).catch((error) => {
      });
    }
  }
  onChangeCallme = (e) => {
    this.setState({fileCallme:e.target.files[0]});
  }

  onFormSubmitYoutube = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file',this.state.fileYoutube);
    formData.append('color',this.state.color);
    formData.append('icon','youtubeIcon');
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'admin/iconsset/icon',formData,config)
          .then((response) => {
            // window.location.reload();
            this.getIconsSet();
          }).catch((error) => {
      });
    }
  }
  onChangeYoutube = (e) => {
    this.setState({fileYoutube:e.target.files[0]});
  }

  onFormSubmitWebsite = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file',this.state.fileWebsite);
    formData.append('color',this.state.color);
    formData.append('icon','websiteIcon');
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'admin/iconsset/icon',formData,config)
          .then((response) => {
            // window.location.reload();
            this.getIconsSet();
          }).catch((error) => {
      });
    }
  }
  onChangeWebsite = (e) => {
    this.setState({fileWebsite:e.target.files[0]});
  }

  onFormSubmitWaze = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file',this.state.fileWaze);
    formData.append('color',this.state.color);
    formData.append('icon','wazeIcon');
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'admin/iconsset/icon',formData,config)
          .then((response) => {
            // window.location.reload();
            this.getIconsSet();
          }).catch((error) => {
      });
    }
  }
  onChangeWaze = (e) => {
    this.setState({fileWaze:e.target.files[0]});
  }

  onFormSubmitFacebook = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file',this.state.fileFacebook);

    console.log(this.state.fileFacebook);
    formData.append('color',this.state.color);
    formData.append('icon','facebookIcon');
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'admin/iconsset/icon',formData,config)
          .then((response) => {
            // window.location.reload();
            this.getIconsSet();
          }).catch((error) => {
      });
    }
  }
  onChangeFacebook = (e) => {
    this.setState({fileFacebook:e.target.files[0]});
  }

  onFormSubmitInstagram = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file',this.state.fileInstagram);
    formData.append('color',this.state.color);
    formData.append('icon','instagramIcon');
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'admin/iconsset/icon',formData,config)
          .then((response) => {
            // window.location.reload();
            this.getIconsSet();
          }).catch((error) => {
      });
    }
  }
  onChangeInstagram = (e) => {
    this.setState({fileInstagram:e.target.files[0]});
  }

  onChange = (e) => {
    this.setState({file:e.target.files[0]});
}
  onChangeBackgroundImage = (e) => {
    this.setState({fileBackgroundImage:e.target.files[0]});
}
  onChangeDefaultCover = (e) => {
    this.setState({fileDefaultCover:e.target.files[0]});
}

  render() {
    return (
      <div>
        <Header />

        <Grid container spacing={2}>
          <Grid item xs={12}>
          <div style={{textAlign: 'right', marginRight: '5%'}}>
            <p style={{direction: 'rtl', fontSize: '2em', marginBottom: 0}} className="headerColor">עריכת סט אייקונים</p>
          </div>
          </Grid>

          <Grid item xs={12} style={{textAlign:'right', marginRight: '5%'}}>
            <div>
              <p style={{fontSize: '1.3em'}}><span style={{color: 'red'}}>*</span> צבע</p>
              <TextField
                value={this.state.color}
                onChange={(e) => this.setState({color: e.target.value})}
                style={{
                  width: '90%',
                  direction: 'rtl',
                  textAlign: 'right',
                  fontSize: '1.3em'
                }}
                inputProps={{
                  style:{
                    direction: 'rtl',
                    textAlign: 'right',
                    fontSize: '1.3em'
                  }
                }}
              />
            </div>
          </Grid>

          <Grid item xs={6} style={{textAlign: 'right', direction: 'rtl'}}>
            <div style={{marginRight: '0%'}}>
            <p style={{fontSize: '1.3em'}}> העלאת כפתור ווטצאפ</p>
            {(this.state.iconsset.whatsappIcon !== '' && this.state.iconsset.whatsappIcon !== null && this.state.iconsset.whatsappIcon !== undefined) && (
              <img src={'https://app.dibit.co.il/templates/'+this.state.iconsset.whatsappIcon} style={{width: 55, height: 55}} />
            )}
            <form onSubmit={this.onFormSubmitWhatsapp}>
              <br />
              <div style={{textAlign: 'right'}}>
              <input type="file" name="myImage" onChange={this.onChangeWhatsapp} accept="image/*"/>
              <br /><br />
              <button type="submit" style={{fontSize: '1.2em'}}>העלאת התמונה</button>
              </div>
            </form>
            </div>
          </Grid>

          <Grid item xs={6} style={{textAlign: 'right', direction: 'rtl'}}>
          <div style={{marginRight: '10%'}}>
          <p style={{fontSize: '1.3em'}}> העלאת כפתור פייסבוק</p>
          {(this.state.iconsset.facebookIcon !== '' && this.state.iconsset.facebookIcon !== null && this.state.iconsset.facebookIcon !== undefined) && (
            <img src={'https://app.dibit.co.il/templates/'+this.state.iconsset.facebookIcon} style={{width: 55, height: 55}} />
          )}
          <form onSubmit={this.onFormSubmitFacebook}>
            <br />
            <div style={{textAlign: 'right'}}>
            <input type="file" name="myImage" onChange={this.onChangeFacebook} accept="image/*"/>
            <br /><br />
            <button type="submit" style={{fontSize: '1.2em'}}>העלאת התמונה</button>
            </div>
          </form>
          </div>
          </Grid>

          <Grid item xs={6} style={{textAlign: 'right', direction: 'rtl'}}>
          <div style={{marginRight: '0%'}}>
          <p style={{fontSize: '1.3em'}}> העלאת כפתור אינסטגרם</p>
          {(this.state.iconsset.instagramIcon !== '' && this.state.iconsset.instagramIcon !== null && this.state.iconsset.instagramIcon !== undefined) && (
            <img src={'https://app.dibit.co.il/templates/'+this.state.iconsset.instagramIcon} style={{width: 55, height: 55}} />
          )}
          <form onSubmit={this.onFormSubmitInstagram}>
            <br />
            <div style={{textAlign: 'right'}}>
            <input type="file" name="myImage" onChange={this.onChangeInstagram} accept="image/*"/>
            <br /><br />
            <button type="submit" style={{fontSize: '1.2em'}}>העלאת התמונה</button>
            </div>
          </form>
          </div>
          </Grid>

          <Grid item xs={6} style={{textAlign: 'right', direction: 'rtl'}}>
          <div style={{marginRight: '10%'}}>
            <p style={{fontSize: '1.3em'}}> העלאת כפתור וויז</p>
            {(this.state.iconsset.wazeIcon !== '' && this.state.iconsset.wazeIcon !== null && this.state.iconsset.wazeIcon !== undefined) && (
              <img src={'https://app.dibit.co.il/templates/'+this.state.iconsset.wazeIcon} style={{width: 55, height: 55}} />
            )}
            <form onSubmit={this.onFormSubmitWaze}>
              <br />
              <div style={{textAlign: 'right'}}>
              <input type="file" name="myImage" onChange={this.onChangeWaze} accept="image/*"/>
              <br /><br />
              <button type="submit" style={{fontSize: '1.2em'}}>העלאת התמונה</button>
              </div>
            </form>
          </div>
          </Grid>

          <Grid item xs={6} style={{textAlign: 'right', direction: 'rtl'}}>
          <p style={{fontSize: '1.3em'}}> העלאת כפתור אייקון לאתר</p>
          {(this.state.iconsset.websiteIcon !== '' && this.state.iconsset.websiteIcon !== null && this.state.iconsset.websiteIcon !== undefined) && (
            <img src={'https://app.dibit.co.il/templates/'+this.state.iconsset.websiteIcon} style={{width: 55, height: 55}} />
          )}
          <form onSubmit={this.onFormSubmitWebsite}>
            <br />
            <div style={{textAlign: 'right'}}>
            <input type="file" name="myImage" onChange={this.onChangeWebsite} accept="image/*"/>
            <br /><br />
            <button type="submit" style={{fontSize: '1.2em'}}>העלאת התמונה</button>
            </div>
          </form>
          </Grid>

          <Grid item xs={6} style={{textAlign: 'right', direction: 'rtl'}}>
          <div style={{marginRight: '10%'}}>
          <p style={{fontSize: '1.3em'}}> העלאת כפתור יוטיוב</p>
          {(this.state.iconsset.youtubeIcon !== '' && this.state.iconsset.youtubeIcon !== null && this.state.iconsset.youtubeIcon !== undefined) && (
            <img src={'https://app.dibit.co.il/templates/'+this.state.iconsset.youtubeIcon} style={{width: 55, height: 55}} />
          )}
          <form onSubmit={this.onFormSubmitYoutube}>
            <br />
            <div style={{textAlign: 'right'}}>
            <input type="file" name="myImage" onChange={this.onChangeYoutube} accept="image/*"/>
            <br /><br />
            <button type="submit" style={{fontSize: '1.2em'}}>העלאת התמונה</button>
            </div>
          </form>
          </div>
          </Grid>

          <Grid item xs={6} style={{textAlign: 'right', direction: 'rtl'}}>
          <p style={{fontSize: '1.3em'}}> העלאת כפתור "התקשרו אליי"</p>
          {(this.state.iconsset.callmeIcon !== '' && this.state.iconsset.callmeIcon !== null && this.state.iconsset.callmeIcon !== undefined) && (
            <img src={'https://app.dibit.co.il/templates/'+this.state.iconsset.callmeIcon} style={{width: 55, height: 55}} />
          )}
          <form onSubmit={this.onFormSubmitCallme}>
            <br />
            <div style={{textAlign: 'right'}}>
            <input type="file" name="myImage" onChange={this.onChangeCallme} accept="image/*"/>
            <br /><br />
            <button type="submit" style={{fontSize: '1.2em'}}>העלאת התמונה</button>
            </div>
          </form>
          </Grid>

          <Grid item xs={6} style={{textAlign: 'right', direction: 'rtl'}}>
          <div style={{marginRight: '10%'}}>
          <p style={{fontSize: '1.3em'}}> העלאת כפתור אייקון לתיק עבודות</p>
          {(this.state.iconsset.portfolioIcon !== '' && this.state.iconsset.portfolioIcon !== null && this.state.iconsset.portfolioIcon !== undefined) && (
            <img src={'https://app.dibit.co.il/templates/'+this.state.iconsset.portfolioIcon} style={{width: 55, height: 55}} />
          )}
          <form onSubmit={this.onFormSubmitPortfolio}>
            <br />
            <div style={{textAlign: 'right'}}>
            <input type="file" name="myImage" onChange={this.onChangePortfolio} accept="image/*"/>
            <br /><br />
            <button type="submit" style={{fontSize: '1.2em'}}>העלאת התמונה</button>
            </div>
          </form>
          </div>
          </Grid>


          <Grid item xs={12} style={{width: '100%', textAlign: 'center'}}>
            <div style={{textAlign: 'center', width: '100%', marginLeft: '5%'}}>
              <Button onClick={(e) => this.updateIconsSet()} style={{fontSize: '1.5em', color: 'white', width: '70%'}} className="addButton">
                אישור ושמירת הסט
              </Button>
              <br /><br />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default EditIconsset;
