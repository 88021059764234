import React, { Component } from 'react';
import logo from '../logo.svg';
import '../App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { SliderPicker, ChromePicker } from 'react-color'

import Switch from '@material-ui/core/Switch';

import Header from '../components/Header';


import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const API = 'https://api.dibit.co.il/v1/';



class AddTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      description: '',
      fontColor: '#fff',
      backgroundColor: '#fff',
      buttonsColor: '#fff',
      buttonsTextColor: '#fff',
      titleColor: '#fff',
      secondaryTitleColor: '#fff',
      textColor: '#fff',
      buttonsType: 'round',
      bottomButtonSidesColor: '#fff',
      bottomButtonCenterColor: '#fff',
      bottomButtonTextColor: '#fff',
      canUploadBackgroundImage: false,
      coverShape: '',
      font: '',
    };
  }


  changeCanUploadBackgroundImage = () => {
    let canUploadBackgroundImage = this.state.canUploadBackgroundImage ? false : true;

    this.setState({canUploadBackgroundImage: canUploadBackgroundImage});
  }

  handleChangeComplete = (color, category) => {
    if (category === "fontColor") this.setState({ fontColor: color.hex });
    else if (category === "backgroundColor") this.setState({ backgroundColor: color.hex });
    else if (category === "buttonsColor") this.setState({ buttonsColor: color.hex });
    else if (category === "buttonsTextColor") this.setState({ buttonsTextColor: color.hex });
    else if (category === "titleColor") this.setState({ titleColor: color.hex });
    else if (category === "secondaryTitleColor") this.setState({ secondaryTitleColor: color.hex });
    else if (category === "textColor") this.setState({ textColor: color.hex });
    else if (category === "bottomButtonSidesColor") this.setState({ bottomButtonSidesColor: color.hex });
    else if (category === "bottomButtonCenterColor") this.setState({ bottomButtonCenterColor: color.hex });
    else if (category === "bottomButtonTextColor") this.setState({ bottomButtonTextColor: color.hex });
    else this.setState({ fontColor: color.hex });
  };

  addTemplate = () => {
    if (
      this.state.name !== ''
    ) {
      try {
        let token = cookies.get('__dato__');

        let payload = {
          name: this.state.name,
          description: this.state.description,
        }

        axios.post(API + 'admin/template', payload, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then(response => {
          if (response.data.status === "ok") {
            // alert('התבנית נוצרה בהצלחה');
            window.location.href = '/template/update?id='+response.data.data.template._id;
          } else {
            alert('חלה שגיאה, אנא נסה בשנית');
          }
        }).catch(err => {
          console.log(err);
          alert('חלה שגיאה, אנא נסה בשנית');
        });
      } catch (err) {
        console.log(err);
        alert('חלה שגיאה, אנא נסה בשנית');
      }
    } else {
      alert('יש למלא את שם התבנית')
    }
  }

  render() {
    return (
      <div>
        <Header />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div style={{textAlign: 'right', marginRight: '5%'}}>
              <p style={{direction: 'rtl', fontSize: '2em'}} className="headerColor">הוספת תבנית</p>

              <p style={{fontSize: '1.3em'}}><span style={{color: 'red'}}>*</span> שם התבנית</p>
              <TextField
                value={this.state.name}
                onChange={(e) => this.setState({name: e.target.value})}
                placeholder="שם התבנית"
                style={{
                  direction: 'rtl',
                  textAlign: 'right',
                  width: '70%',
                  fontSize:'1.3em',
                  color: 'black'
                }}
                inputProps={{
                  style:{
                    fontSize:'1.3em',
                    color: 'black'
                  }
                }}
              />

              <p style={{fontSize: '1.3em'}}>תיאור התבנית</p>
              <TextField
                value={this.state.description}
                onChange={(e) => this.setState({description: e.target.value})}
                placeholder="תיאור התבנית"
                style={{
                  direction: 'rtl',
                  textAlign: 'right',
                  width: '70%',
                  fontSize:'1.3em',
                  color: 'black'
                }}
                inputProps={{
                  style:{
                    fontSize:'1.3em',
                    color: 'black'
                  }
                }}
              />

              <br /><br />
              <Button onClick={(e) => this.addTemplate()} style={{maxWidth: '70%', fontSize: '1.2em', color: 'white'}} variant='contained' className="addButton">
                אישור והמשך להגדרות מתקדמות
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default AddTemplate;
