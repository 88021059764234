import React, { Component } from 'react';
import logo from '../logo.svg';
import '../App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import EditIcon from '@material-ui/icons/Edit';

import {
 Link
} from "react-router-dom";

import Header from '../components/Header';

import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const API = 'https://api.dibit.co.il/v1/';


class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: {
        'user': {},
        'package': {},
        'card': {}
      },
      packages: [],
      clientId: ''
    };
  }

  componentDidMount() {
    this.getData();
    this.getPackages();
  }

  getData = () => {
    let url = window.location.href;
    let name = 'id';
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    this.setState({clientId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
      try {
        let token = cookies.get('__dato__');
        axios.get(API + 'admin/client?id='+this.state.clientId, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then(response => {
          console.log(response);
          if (response.data.status === "ok") {
            this.setState({
              client: response.data.client
            });
          }
        }).catch(err => {
          console.log(err);
          alert('הייתה שגיאה בקבלת המידע, אנא נסה להתנתק ולהתחבר בשנית');
        });
      } catch (err) {
        console.log(err);
        alert('הייתה שגיאה בקבלת המידע, אנא נסה להתנתק ולהתחבר בשנית');
      }
    });
  }

  getPackages = () => {
    let url = window.location.href;
    let name = 'id';
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    this.setState({clientId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
      try {
        let token = cookies.get('__dato__');
        axios.get(API + 'admin/packages', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then(response => {
          console.log(response);
          if (response.data.status === "ok") {
            this.setState({
              packages: response.data.data.packages
            });
          }
        }).catch(err => {
          console.log(err);
          alert('הייתה שגיאה בקבלת המידע, אנא נסה להתנתק ולהתחבר בשנית');
        });
      } catch (err) {
        console.log(err);
        alert('הייתה שגיאה בקבלת המידע, אנא נסה להתנתק ולהתחבר בשנית');
      }
    });
  }

  changePackage = () => {
    try {
      let token = cookies.get('__dato__');
      axios.put(API + 'admin/client/package?id='+this.state.clientId, {
        package: this.state.packageChosen
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.getData();
        }
      }).catch(err => {
        console.log(err);
        alert('הייתה שגיאה בקבלת המידע, אנא נסה להתנתק ולהתחבר בשנית');
      });
    } catch (err) {
      console.log(err);
      alert('הייתה שגיאה בקבלת המידע, אנא נסה להתנתק ולהתחבר בשנית');
    }
  }

  removeClient = () => {
    try {
      let token = cookies.get('__dato__');
      axios.delete(API + 'admin/client?id='+this.state.clientId, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          window.location.href='/clients';
        }
      }).catch(err => {
        console.log(err);
        alert('הייתה שגיאה בקבלת המידע, אנא נסה להתנתק ולהתחבר בשנית');
      });
    } catch (err) {
      console.log(err);
      alert('הייתה שגיאה בקבלת המידע, אנא נסה להתנתק ולהתחבר בשנית');
    }
  }

  modifyDate = (date) => {
    let d = new Date(date);
    try {
      let day = d.getDate().toString();
      if (day.length === 1) day = '0' + day;
      let month = (d.getMonth() + 1).toString();
      if (month.length === 1) month = '0' + month;

      return day + '/' + month + '/' + d.getFullYear();
    } catch (err) {
      return '-';
    }

    return '-';
  }

  render() {
    return (
      <div>
        <Header />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <center>
            <div style={{marginRight: '10%'}}>
              <p className="headerColor" style={{fontSize:'2em', direction: 'rtl', textAlign: 'right', textDecoration: 'underline'}}>ניהול לקוח</p>
            </div>
            <TableContainer component={Paper} style={{maxWidth: '80%'}}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>מחיקת הלקוח</TableCell>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>הצטרף בתאריך</TableCell>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>לינק לכרטיס</TableCell>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>חבילה</TableCell>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>מספר טלפון</TableCell>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>אימייל</TableCell>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>שם הלקוח</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={'_user'}>
                    <TableCell align="right" style={{fontSize: '1.2em'}}>
                      <Button style={{backgroundColor: 'red', color: 'white', fontSize:'0.8em'}} onClick={(e) => this.removeClient(this.state.client.user._id)}>לחץ כאן למחיקה</Button>
                    </TableCell>
                    <TableCell align="right" style={{fontSize: '1.2em'}}>
                      {this.modifyDate(this.state.client.user.createdAt)}
                    </TableCell>
                    <TableCell align="right" style={{fontSize: '1.2em', textDecoration: 'underline'}} onClick={(e) => window.open('https://dibit.co.il/'+this.state.client.card.slug, '_blank')}>{'https://dibit.co.il/'+this.state.client.card.slug}</TableCell>
                    <TableCell align="right" style={{fontSize: '1.2em'}}>{this.state.client.package.name}</TableCell>
                    <TableCell align="right" style={{fontSize: '1.2em'}}>{
                      (this.state.client.user.phoneNumber === '' || this.state.client.user.phoneNumber === undefined || this.state.client.user.phoneNumber === null) ? '-' : this.state.client.user.phoneNumber
                    }</TableCell>
                    <TableCell align="right" style={{fontSize: '1.2em'}}>{
                      (this.state.client.user.email === '' || this.state.client.user.email === undefined || this.state.client.user.email === null) ? '-' : this.state.client.user.email
                    }</TableCell>
                    <TableCell align="right" style={{fontSize: '1.2em'}}>{((this.state.client.user.firstName + ' ' + this.state.client.user.lastName) === '') ? '-' : (this.state.client.user.firstName + ' ' + this.state.client.user.lastName)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            </center>

            <br />
            <div style={{textAlign: 'right', marginRight: '10%'}}>
              <p style={{fontSize: '1.6em', textDecoration: 'underline'}}>שדרוג/שנמוך חבילת הלקוח</p>

              <Select
                value={this.state.client.package._id}
                onChange={(e) => this.setState({packageChosen: e.target.value})}
                style={{
                  width: '50%',
                  fontSize: '1.3em',
                  direction: 'rtl',
                  textAlign: 'right'
                }}
                inputProps={{
                  style:{
                    fontSize: '1.3em'
                  }
                }}
              >
                {this.state.packages.reverse().map((package_, i) => {
                  return (
                    <MenuItem style={{direction: 'rtl', textAlign: 'right', fontSize: '1.3em'}} value={package_._id}>{package_.name}</MenuItem>
                  );
                })}
              </Select>
              <br />
              <br />
              <Button onClick={(e) => this.changePackage()} className="addButton" style={{fontSize:'1.3em', color: 'white'}}>שמירת השינויים</Button>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Clients;
