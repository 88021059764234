import React, { Component } from 'react';
import logo from '../logo.svg';
import '../App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { SliderPicker, ChromePicker } from 'react-color'

import Switch from '@material-ui/core/Switch';

import Header from '../components/Header';


import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const API = 'https://api.dibit.co.il/v1/';



class AddIconsset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: '',
    };
  }

  addIconsSet = () => {
    if (
      this.state.color !== ''
    ) {
      try {
        let token = cookies.get('__dato__');

        let payload = {
          color: this.state.color,
        }

        axios.post(API + 'admin/iconsset', payload, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then(response => {
          if (response.data.status === "ok") {
            // alert('התבנית נוצרה בהצלחה');
            window.location.href = '/iconsset/update?id='+response.data.data.iconsset._id;
          } else {
            alert('חלה שגיאה, אנא נסה בשנית');
          }
        }).catch(err => {
          console.log(err);
          alert('חלה שגיאה, אנא נסה בשנית');
        });
      } catch (err) {
        console.log(err);
        alert('חלה שגיאה, אנא נסה בשנית');
      }
    } else {
      alert('יש למלא את הצבע')
    }
  }

  render() {
    return (
      <div>
        <Header />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div style={{textAlign: 'right', marginRight: '5%'}}>
              <p style={{direction: 'rtl', fontSize: '2em'}} className="headerColor">הוספת סט אייקונים</p>

              <p style={{fontSize: '1.3em'}}><span style={{color: 'red'}}>*</span> צבע</p>
              <TextField
                value={this.state.color}
                onChange={(e) => this.setState({color: e.target.value})}
                placeholder="צבע"
                style={{
                  direction: 'rtl',
                  textAlign: 'right',
                  width: '70%',
                  fontSize:'1.3em',
                  color: 'black'
                }}
                inputProps={{
                  style:{
                    fontSize:'1.3em',
                    color: 'black'
                  }
                }}
              />


              <br /><br />
              <Button onClick={(e) => this.addIconsSet()} style={{maxWidth: '70%', fontSize: '1.2em', color: 'white'}} variant='contained' className="addButton">
                אישור והמשך להגדרות מתקדמות
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default AddIconsset;
