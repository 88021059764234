import React, { Component } from 'react';
import logo from '../logo.svg';
import '../App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import EditIcon from '@material-ui/icons/Edit';

import {
 Link
} from "react-router-dom";

import Header from '../components/Header';

import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const API = 'https://api.dibit.co.il/v1/';


class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: [],
      packages: [],
      users: [],
      cards: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    try {
      let token = cookies.get('__dato__');

      axios.get(API + 'admin', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.setState({
            templates: response.data.data.templates,
            packages: response.data.data.packages,
            users: response.data.data.users,
            cards: response.data.data.cards,
          }, () => {
            let templates = this.state.templates !== undefined ? this.state.templates.reverse() : this.state.templates;
            let packages = this.state.packages !== undefined ? this.state.packages.reverse(): this.state.packages;
            let users = this.state.users !== undefined ? this.state.users.reverse(): this.state.users;
            let cards = this.state.cards !== undefined ? this.state.cards.reverse(): this.state.cards;

            this.setState({
              templates: templates,
              packages: packages,
              users: users,
              cards: cards,
            });
          });
        }
      }).catch(err => {
        console.log(err);
        alert('הייתה שגיאה בקבלת המידע, אנא נסה להתנתק ולהתחבר בשנית');
      });
    } catch (err) {
      console.log(err);
      alert('הייתה שגיאה בקבלת המידע, אנא נסה להתנתק ולהתחבר בשנית');
    }
  }

  modifyDate = (date) => {
    let d = new Date(date);
    try {
      let day = d.getDate().toString();
      if (day.length === 1) day = '0' + day;
      let month = (d.getMonth() + 1).toString();
      if (month.length === 1) month = '0' + month;

      return day + '/' + month + '/' + d.getFullYear();
    } catch (err) {
      return '-';
    }

    return '-';
  }

  render() {
    return (
      <div>
        <Header />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <center>
            <div style={{marginRight: '10%'}}>
              <p className="headerColor" style={{fontSize:'2em', direction: 'rtl', textAlign: 'right', textDecoration: 'underline'}}>הלקוחות שלי</p>
            </div>
            <TableContainer component={Paper} style={{maxWidth: '80%'}}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>ניהול</TableCell>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>הצטרף בתאריך</TableCell>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>לינק לכרטיס</TableCell>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>חבילה</TableCell>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>מספר טלפון</TableCell>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>אימייל</TableCell>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>שם הלקוח</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.users.reverse().map((item, i) => {
                    return (
                      <TableRow key={i+'_user'}>
                        <TableCell align="right" style={{fontSize: '1.2em'}}>
                          <Link to={'/client/manage?id='+item._id} style={{textDecoration: 'none'}}>
                          <IconButton>
                            <EditIcon style={{width: 32, height: 32, color: 'black'}} />
                          </IconButton>
                          </Link>
                        </TableCell>
                        <TableCell align="right" style={{fontSize: '1.2em'}}>
                          {this.modifyDate(item.createdAt)}
                        </TableCell>
                        <TableCell align="right" style={{fontSize: '1.2em', textDecoration: 'underline'}} onClick={(e) => window.open(item.link, '_blank')}>{item.link}</TableCell>
                        <TableCell align="right" style={{fontSize: '1.2em'}}>{item.package.name}</TableCell>
                        <TableCell align="right" style={{fontSize: '1.2em'}}>{
                          (item.phoneNumber === '' || item.phoneNumber === undefined || item.phoneNumber === null) ? '-' : item.phoneNumber
                        }</TableCell>
                        <TableCell align="right" style={{fontSize: '1.2em'}}>{
                          (item.email === '' || item.email === undefined || item.email === null) ? '-' : item.email
                        }</TableCell>
                        <TableCell align="right" style={{fontSize: '1.2em'}}>{((item.firstName + ' ' + item.lastName) === '') ? '-' : (item.firstName + ' ' + item.lastName)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            </center>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Clients;
