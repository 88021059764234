import React, { Component } from 'react';
import logo from '../logo.svg';
import '../App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';

import {
 Link
} from "react-router-dom";

import Cookies from 'universal-cookie';
import Drawer from '@material-ui/core/Drawer';

const cookies = new Cookies();

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  logout = () => {
    cookies.remove('__dato__');
    window.location.reload();
  }

  render() {
    return (
      <AppBar position="static">
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Link to='/' style={{textDecoration: 'none', color: 'white'}}>
            <Button style={{color: 'white', textTransform: 'none', width: '120%'}}>
              <Typography variant="h6">
                Dibit
              </Typography>
            </Button>
          </Link>

          <div style={{marginTop: '0%', width: '100%', justifyContent: 'flex-end'}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
              <Link style={{textDecoration: 'none', color: 'white'}}><span style={{marginRight: '3%', marginLeft: '2%', fontSize:'1.5em'}} onClick={(e) => this.logout()}>התנתק</span></Link>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Link to='/packages' style={{textDecoration: 'none', color: 'white'}}><span style={{marginRight: '3%', marginLeft: '2%', fontSize: '1.5em'}}>חבילות</span></Link>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Link to='/categories' style={{textDecoration: 'none', color: 'white'}}><span style={{marginRight: '3%', marginLeft: '2%', fontSize: '1.5em'}}>קטגוריות</span></Link>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Link to='/templates' style={{textDecoration: 'none', color: 'white'}}><span style={{marginRight: '3%', marginLeft: '2%', fontSize: '1.5em'}}>תבניות</span></Link>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Link to='/images' style={{textDecoration: 'none', color: 'white'}}><span style={{marginRight: '3%', marginLeft: '2%', fontSize: '1.5em'}}>גלרייה</span></Link>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Link to='/payments' style={{textDecoration: 'none', color: 'white'}}><span style={{marginRight: '3%', marginLeft: '2%', fontSize: '1.5em'}}>תשלומים וחשבוניות</span></Link>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Link to='/clients' style={{textDecoration: 'none', color: 'white'}}><span style={{marginRight: '3%', marginLeft: '2%', fontSize: '1.5em'}}>משתמשים</span></Link>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Link to='/' style={{textDecoration: 'none', color: 'white'}}><span style={{marginRight: '3%', marginLeft: '2%', fontSize: '1.5em'}}>בית</span></Link>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

export default Header;
