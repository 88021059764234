import React, { Component } from 'react';
import logo from '../logo.svg';
import '../App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { SliderPicker, ChromePicker } from 'react-color'

import Switch from '@material-ui/core/Switch';

import Header from '../components/Header';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DeleteIcon from '@material-ui/icons/Delete';

import ImageUploader from 'react-images-upload';

import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const API = 'https://api.dibit.co.il/v1/';

class Images extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      tagEnglish: '',
      file: null,
      pictures: [],
      gallery: [],
    };
  }

  componentDidMount () {
    this.getData();
  }

  getData = () => {
    try {
      let token = cookies.get('__dato__');

      axios.get(API + 'admin/gallery/images', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.setState({
            gallery: response.data.gallery
          });
        }
      }).catch(err => {
        console.log(err);
        alert('הייתה שגיאה בקבלת המידע, אנא נסה להתנתק ולהתחבר בשנית');
      });
    } catch (err) {
      console.log(err);
      alert('הייתה שגיאה בקבלת המידע, אנא נסה להתנתק ולהתחבר בשנית');
    }
  }

  deleteImage = (id) => {
    try {
      let token = cookies.get('__dato__');

      axios.delete(API + 'admin/gallery-image?id='+id, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.getData();
        }
      }).catch(err => {
        console.log(err);
        alert('הייתה שגיאה בקבלת המידע, אנא נסה להתנתק ולהתחבר בשנית');
      });
    } catch (err) {
      console.log(err);
      alert('הייתה שגיאה בקבלת המידע, אנא נסה להתנתק ולהתחבר בשנית');
    }
  }

  onFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file',this.state.file);
    formData.append('tagEnglish',this.state.tagEnglish);
    formData.append('name',this.state.name);
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'admin/gallery/upload',formData,config)
          .then((response) => {
            alert('התמונה הועלתה בהצלחה');
            window.location.reload();
            this.getMyCard();
          }).catch((error) => {
      });
    }
  }

  onChange = (e) => {
      this.setState({file:e.target.files[0]});
  }

  render() {
    return (
      <div>
        <Header />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div style={{textAlign: 'right', marginRight: '5%', marginTop: 0, marginBottom: 0}}>
              <p style={{direction: 'rtl', fontSize: '2em'}} className="headerColor">גלריית התמונות</p>

              <p style={{fontSize: '1.7em', textDecoration: 'underline'}}>העלאת תמונות חדשות</p>
              <div>
                <form onSubmit={this.onFormSubmit}>
                  <p style={{fontSize: '1.3em'}}>טאג התמונה באנגלית (מילה אחת)</p>
                  <TextField
                    value={this.state.tagEnglish}
                    onChange={(e) => this.setState({tagEnglish: e.target.value})}
                    style={{
                      backgroundColor: 'white',
                      width: '50%',
                      color: 'black',
                      textAlign: 'right',
                      direction: 'rtl',
                      fontSize: '1.3em'
                    }}
                    inputProps={{
                      style:{
                        backgroundColor: 'white',
                        width: '100%',
                        color: 'black',
                        textAlign: 'right',
                        direction: 'rtl',
                        fontSize: '1.3em'
                      }
                    }}
                  />
                  <p style={{fontSize: '1.3em'}}>שם התמונה בעברית (לחיפוש)</p>
                  <TextField
                    value={this.state.name}
                    onChange={(e) => this.setState({name: e.target.value})}
                    style={{
                      backgroundColor: 'white',
                      width: '50%',
                      color: 'black',
                      textAlign: 'right',
                      direction: 'rtl',
                      fontSize: '1.3em'
                    }}
                    inputProps={{
                      style:{
                        backgroundColor: 'white',
                        width: '100%',
                        color: 'black',
                        textAlign: 'right',
                        direction: 'rtl',
                        fontSize: '1.3em'
                      }
                    }}
                  />
                  <br /><br />

                  <input type="file" name="myImage" onChange={this.onChange} accept="image/*" className="addButton"/>
                  <br /><br />

                  <button type="submit" className="addButton" style={{border: '0px solid transparent', borderRadius: 20, padding: '0.5%'}}>העלאת התמונה לגלרייה</button>
                </form>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <p style={{textAlign: 'right', marginRight: '5%', fontSize: '1.7em', textDecoration: 'underline'}}>הגלריה של דיביט</p>
            <center>
            <TableContainer component={Paper} style={{maxWidth: '90%'}}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right" style={{fontSize: '1.2em'}}>מחיקה</TableCell>
                    <TableCell align="right" style={{fontSize: '1.2em'}}>תמונה</TableCell>
                    <TableCell align="right" style={{fontSize: '1.2em'}}>שם</TableCell>
                    <TableCell align="right" style={{fontSize: '1.2em'}}>שם (אנגלית)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.gallery.map((index, i) => {
                    return (
                      <TableRow key={i.toString()+'_image'}>
                        <TableCell align="right" style={{fontSize: '1.2em'}}>
                          <IconButton onClick={(e) => this.deleteImage(index._id)}>
                            <DeleteForeverIcon style={{width: 32, height: 32, color: 'red'}}  onClick={(e) => this.deleteImage(index._id)}/>
                          </IconButton>
                        </TableCell>
                        <TableCell align="right" style={{fontSize: '1.2em'}}>
                          <img src={'https://app.dibit.co.il/gallery/'+index.image} style={{maxWidth: '200px'}}/>
                        </TableCell>
                        <TableCell align="right" style={{fontSize: '1.2em'}}>
                          {index.name}
                        </TableCell>
                        <TableCell align="right" style={{fontSize: '1.2em'}}>
                          {index.tagEnglish}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            </center>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Images;
