import React, { Component } from 'react';
import logo from '../logo.svg';
import '../App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import EditIcon from '@material-ui/icons/Edit';

import {
 Link
} from "react-router-dom";

import Header from '../components/Header';

import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const API = 'https://api.dibit.co.il/v1/';


class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stos: [],
      invoices: [],
      payments: []
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    try {
      let token = cookies.get('__dato__');

      axios.get(API + 'admin/payments', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.setState({
            stos: response.data.data.stos,
            invoices: response.data.data.invoices,
            payments: response.data.data.payments,
          });
        }
      }).catch(err => {
        console.log(err);
        alert('הייתה שגיאה בקבלת המידע, אנא נסה להתנתק ולהתחבר בשנית');
      });
    } catch (err) {
      console.log(err);
      alert('הייתה שגיאה בקבלת המידע, אנא נסה להתנתק ולהתחבר בשנית');
    }
  }

  modifyDate = (date) => {
    let d = new Date(date);
    try {
      let day = d.getDate().toString();
      if (day.length === 1) day = '0' + day;
      let month = (d.getMonth() + 1).toString();
      if (month.length === 1) month = '0' + month;

      return day + '/' + month + '/' + d.getFullYear();
    } catch (err) {
      return '-';
    }

    return '-';
  }

  render() {
    return (
      <div>
        <Header />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <center>
            <div style={{marginRight: '10%'}}>
              <p className="headerColor" style={{fontSize:'2em', direction: 'rtl', textAlign: 'right', textDecoration: 'underline'}}>הוראות קבע</p>
            </div>
            <TableContainer component={Paper} style={{maxWidth: '80%'}}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>תשלום הבא יבוצע בתאריך</TableCell>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>תשלום אחרון בוצע בתאריך</TableCell>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>מחיר חודשי (לא כולל מע"מ)</TableCell>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>חבילה</TableCell>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>אימייל</TableCell>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>שם הלקוח</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.stos.reverse().map((item, i) => {
                    return (
                      <TableRow key={i+'_user'}>
                        <TableCell align="right" style={{fontSize: '1.2em'}}>
                          {item.nextPaymentAt}
                        </TableCell>
                        <TableCell align="right" style={{fontSize: '1.2em'}}>
                          {item.date}
                        </TableCell>
                        <TableCell align="right" style={{fontSize: '1.2em'}}>₪{item.package.price}</TableCell>
                        <TableCell align="right" style={{fontSize: '1.2em'}}>{item.package.name}</TableCell>
                        <TableCell align="right" style={{fontSize: '1.2em'}}>{
                          item.user.email
                        }</TableCell>
                        <TableCell align="right" style={{fontSize: '1.2em'}}>{item.user.firstName + ' ' + item.user.lastName}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{marginRight: '10%'}}>
              <p className="headerColor" style={{fontSize:'2em', direction: 'rtl', textAlign: 'right', textDecoration: 'underline'}}>תשלומים שבוצעו</p>
            </div>
            <TableContainer component={Paper} style={{maxWidth: '80%'}}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>תאריך העסקה</TableCell>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>על סה"כ</TableCell>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>חבילה</TableCell>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>אימייל</TableCell>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>שם הלקוח</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.payments.reverse().map((item, i) => {
                    return (
                      <TableRow key={i+'_user'}>
                        <TableCell align="right" style={{fontSize: '1.2em'}}>
                          {this.modifyDate(item.createdAt)}
                        </TableCell>
                        <TableCell align="right" style={{fontSize: '1.2em'}}>₪{parseFloat(item.amount).toFixed(2)}</TableCell>
                        <TableCell align="right" style={{fontSize: '1.2em'}}>{item.package.name}</TableCell>
                        <TableCell align="right" style={{fontSize: '1.2em'}}>{
                          item.user.email
                        }</TableCell>
                        <TableCell align="right" style={{fontSize: '1.2em'}}>{item.user.firstName + ' ' + item.user.lastName}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{marginRight: '10%'}}>
              <p className="headerColor" style={{fontSize:'2em', direction: 'rtl', textAlign: 'right', textDecoration: 'underline'}}>חשבוניות</p>
            </div>
            <TableContainer component={Paper} style={{maxWidth: '80%'}}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>מזהה חשבונית-ירוקה</TableCell>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>תאריך הפקה</TableCell>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>חשבונית</TableCell>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>אימייל</TableCell>
                    <TableCell align="right" style={{fontSize: '1.5em'}}>שם הלקוח</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.invoices.reverse().map((item, i) => {
                    return (
                      <TableRow key={i+'_user'}>
                        <TableCell align="right" style={{fontSize: '1.2em'}}>
                          {item.greeninvoiceId}
                        </TableCell>
                        <TableCell align="right" style={{fontSize: '1.2em'}}>
                          {this.modifyDate(item.createdAt)}
                        </TableCell>
                        <TableCell align="right" style={{fontSize: '1.2em'}}>
                          <a href={item.link} target="_blank">לחץ לצפייה בחשבונית</a>
                        </TableCell>
                        <TableCell align="right" style={{fontSize: '1.2em'}}>{
                          item.user.email
                        }</TableCell>
                        <TableCell align="right" style={{fontSize: '1.2em'}}>{item.user.firstName + ' ' + item.user.lastName}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            </center>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Payments;
