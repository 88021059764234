import React, { Component } from 'react';
import logo from '../logo.svg';
import '../App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { SliderPicker, ChromePicker } from 'react-color'

import Switch from '@material-ui/core/Switch';

import Header from '../components/Header';


import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const API = 'https://api.dibit.co.il/v1/';

class EditTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      description: '',
      fontColor: '#fff',
      backgroundColor: '#fff',
      buttonsColor: '#fff',
      buttonsTextColor: '#fff',
      titleColor: '#fff',
      secondaryTitleColor: '#fff',
      textColor: '#fff',
      buttonsType: 'round',
      canUploadBackgroundImage: false,
      haveBackgroundImage: false,
      bottomButtonSidesColor: '#fff',
      bottomButtonCenterColor: '#fff',
      bottomButtonTextColor: '#fff',
      templateId: '',
      coverShape: '',
      font: '',
      coverImage: '',
      defaultCover: '',
      backgroundImage: '',
      template: {},
      button1: '',
      button2: '',
      button3: '',
      button4: '',
      button5: '',
      button6: '',
      button7: '',
      button8: '',
      button9: '',
      iconssetId: '',
      categories: [],
      iconssets: []
    };
  }

  componentDidMount () {
    this.getTemplate();
    this.getCategories();
    this.getIconsset();
  }

  changeCanUploadBackgroundImage = () => {
    let canUploadBackgroundImage = this.state.canUploadBackgroundImage ? false : true;

    this.setState({canUploadBackgroundImage: canUploadBackgroundImage});
  }

  handleChangeComplete = (color, category) => {
    if (category === "fontColor") this.setState({ fontColor: color.hex });
    else if (category === "backgroundColor") this.setState({ backgroundColor: color.hex });
    else if (category === "buttonsColor") this.setState({ buttonsColor: color.hex });
    else if (category === "buttonsTextColor") this.setState({ buttonsTextColor: color.hex });
    else if (category === "titleColor") this.setState({ titleColor: color.hex });
    else if (category === "secondaryTitleColor") this.setState({ secondaryTitleColor: color.hex });
    else if (category === "textColor") this.setState({ textColor: color.hex });
    else if (category === "bottomButtonSidesColor") this.setState({ bottomButtonSidesColor: color.hex });
    else if (category === "bottomButtonCenterColor") this.setState({ bottomButtonCenterColor: color.hex });
    else if (category === "bottomButtonTextColor") this.setState({ bottomButtonTextColor: color.hex });
    else this.setState({ fontColor: color.hex });
  };


  getCategories = () => {
    try {
      let token = cookies.get('__dato__');

      axios.get(API + 'admin/categories', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.setState({
            categories: response.data.data.categories
          });
        }
      }).catch(err => {
        console.log(err);
        alert('הייתה שגיאה בקבלת המידע, אנא נסה להתנתק ולהתחבר בשנית');
      });
    } catch (err) {
      console.log(err);
      alert('הייתה שגיאה בקבלת המידע, אנא נסה להתנתק ולהתחבר בשנית');
    }
  }

  getIconsset = () => {
    try {
      let token = cookies.get('__dato__');

      axios.get(API + 'admin/iconssets', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.setState({
            iconssets: response.data.data.iconssets
          });
        }
      }).catch(err => {
        console.log(err);
        alert('הייתה שגיאה בקבלת המידע, אנא נסה להתנתק ולהתחבר בשנית');
      });
    } catch (err) {
      console.log(err);
      alert('הייתה שגיאה בקבלת המידע, אנא נסה להתנתק ולהתחבר בשנית');
    }
  }

  getTemplate = () => {
    let url = window.location.href;
    let name = 'id';
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    this.setState({templateId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
      try {
        let token = cookies.get('__dato__');

        axios.get(API + 'admin/template?id='+this.state.templateId, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then(response => {
          console.log(response);
          if (response.data.status === "ok") {
            this.setState({
              template: response.data.data.template,
              name: response.data.data.template.name,
              category: response.data.data.template.category,
              description: response.data.data.template.description,
              fontColor: response.data.data.template.fontColor,
              backgroundColor: response.data.data.template.backgroundColor,
              iconssetId: response.data.data.template.iconssetId,
              buttonsColor: response.data.data.template.buttonsColor,
              buttonsTextColor: response.data.data.template.buttonsTextColor,
              titleColor: response.data.data.template.titleColor,
              secondaryTitleColor: response.data.data.template.secondaryTitleColor,
              textColor: response.data.data.template.textColor,
              buttonsType: response.data.data.template.buttonsType,
              canUploadBackgroundImage: response.data.data.template.canUploadBackgroundImage,
              bottomButtonSidesColor: response.data.data.template.bottomButtonSidesColor,
              bottomButtonCenterColor: response.data.data.template.bottomButtonCenterColor,
              bottomButtonTextColor: response.data.data.template.bottomButtonTextColor,
              coverShape: response.data.data.template.coverShape,
              font: response.data.data.template.font,
              coverImage: response.data.data.template.coverImage,
              defaultCover: response.data.data.template.defaultCover,
              backgroundImage: response.data.data.template.backgroundImage,
              button1: response.data.data.template.button1,
              button2: response.data.data.template.button2,
              button3: response.data.data.template.button3,
              button4: response.data.data.template.button4,
              button5: response.data.data.template.button5,
              button6: response.data.data.template.button6,
              button7: response.data.data.template.button7,
              button8: response.data.data.template.button8,
              button9: response.data.data.template.button9,
              category: response.data.data.template.category,
              haveBackgroundImage: response.data.data.template.haveBackgroundImage,
            });
          } else {
            alert('חלה שגיאה בקבלת הנתונים, אנא נסו שוב');
          }
        }).catch(err => {
          console.log(err);
          alert('חלה שגיאה בקבלת הנתונים, אנא נסו שוב');
        });
      } catch (err) {
        console.log(err);
        alert('חלה שגיאה בקבלת הנתונים, אנא נסו שוב');
      }
    });
  }


  updateTemplate = () => {
    // if (
    //   this.state.name !== '' &&
    //   this.state.description !== '' &&
    //   this.state.fontColor !== '' &&
    //   this.state.backgroundColor !== '' &&
    //   this.state.buttonsColor !== '' &&
    //   this.state.buttonsTextColor !== '' &&
    //   this.state.titleColor !== '' &&
    //   this.state.secondaryTitleColor !== '' &&
    //   this.state.textColor !== '' &&
    //   this.state.buttonsType !== '' &&
    //   this.state.canUploadBackgroundImage !== ''
    // ) {
      try {
        let token = cookies.get('__dato__');

        let payload = {
          name: this.state.name,
          description: this.state.description,
          fontColor: this.state.fontColor,
          backgroundColor: this.state.backgroundColor,
          buttonsColor: this.state.buttonsColor,
          buttonsTextColor: this.state.buttonsTextColor,
          iconssetId: this.state.iconssetId,
          titleColor: this.state.titleColor,
          secondaryTitleColor: this.state.secondaryTitleColor,
          textColor: this.state.textColor,
          buttonsType: this.state.buttonsType,
          canUploadBackgroundImage: this.state.canUploadBackgroundImage,
          bottomButtonSidesColor: this.state.bottomButtonSidesColor,
          bottomButtonCenterColor: this.state.bottomButtonCenterColor,
          bottomButtonTextColor: this.state.bottomButtonTextColor,
          coverShape: this.state.coverShape,
          font: this.state.font,
          button1: this.state.button1,
          button2: this.state.button2,
          button3: this.state.button3,
          button4: this.state.button4,
          button5: this.state.button5,
          button6: this.state.button6,
          button7: this.state.button7,
          button8: this.state.button8,
          button9: this.state.button9,
          category: this.state.category,
          haveBackgroundImage: this.state.haveBackgroundImage,
        }

        axios.put(API + 'admin/template?id=' + this.state.templateId, payload, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then(response => {
          console.log(response);
          if (response.data.status === "ok") {
            alert('התבנית עודכנה בהצלחה');
          } else {
            alert('חלה שגיאה, אנא נסה בשנית');
          }
        }).catch(err => {
          console.log(err);
          alert('חלה שגיאה, אנא נסה בשנית');
        });
      } catch (err) {
        console.log(err);
        alert('חלה שגיאה, אנא נסה בשנית');
      }
    // } else {
    //   alert('יש למלא את כל השדות על מנת ליצור תבנית')
    // }
  }

  onFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file',this.state.file);
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'admin/template/cover?id='+this.state.templateId,formData,config)
          .then((response) => {
            // window.location.reload();
            this.getTemplate();
          }).catch((error) => {
      });
    }
  }
  onFormSubmitDefaultCover = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file',this.state.fileDefaultCover);
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'admin/template/default-cover?id='+this.state.templateId,formData,config)
          .then((response) => {
            // window.location.reload();
            this.getTemplate();
          }).catch((error) => {
            console.log(error);
      });
    }
  }
  onFormSubmitBackgroundImage = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file',this.state.fileBackgroundImage);
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'admin/template/background-image?id='+this.state.templateId,formData,config)
          .then((response) => {
            // window.location.reload();
            this.getTemplate();
          }).catch((error) => {
            console.log(error);
      });
    }
  }

  onFormSubmitWhatsapp = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file',this.state.fileWhatsapp);
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'admin/template/icon?id='+this.state.templateId+'&btn=whatsapp',formData,config)
          .then((response) => {
            // window.location.reload();
            this.getTemplate();
          }).catch((error) => {
      });
    }
  }
  onChangeWhatsapp = (e) => {
    this.setState({fileWhatsapp:e.target.files[0]});
  }

  onFormSubmitPortfolio = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file',this.state.filePortfolio);
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'admin/template/icon?id='+this.state.templateId+'&btn=portfolio',formData,config)
          .then((response) => {
            // window.location.reload();
            this.getTemplate();
          }).catch((error) => {
      });
    }
  }
  onChangePortfolio = (e) => {
    this.setState({filePortfolio:e.target.files[0]});
  }

  onFormSubmitCallme = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file',this.state.fileCallme);
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'admin/template/icon?id='+this.state.templateId+'&btn=callme',formData,config)
          .then((response) => {
            // window.location.reload();
            this.getTemplate();
          }).catch((error) => {
      });
    }
  }
  onChangeCallme = (e) => {
    this.setState({fileCallme:e.target.files[0]});
  }

  onFormSubmitYoutube = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file',this.state.fileYoutube);
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'admin/template/icon?id='+this.state.templateId+'&btn=youtube',formData,config)
          .then((response) => {
            // window.location.reload();
            this.getTemplate();
          }).catch((error) => {
      });
    }
  }
  onChangeYoutube = (e) => {
    this.setState({fileYoutube:e.target.files[0]});
  }

  onFormSubmitWebsite = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file',this.state.fileWebsite);
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'admin/template/icon?id='+this.state.templateId+'&btn=website',formData,config)
          .then((response) => {
            // window.location.reload();
            this.getTemplate();
          }).catch((error) => {
      });
    }
  }
  onChangeWebsite = (e) => {
    this.setState({fileWebsite:e.target.files[0]});
  }

  onFormSubmitWaze = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file',this.state.fileWaze);
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'admin/template/icon?id='+this.state.templateId+'&btn=waze',formData,config)
          .then((response) => {
            // window.location.reload();
            this.getTemplate();
          }).catch((error) => {
      });
    }
  }
  onChangeWaze = (e) => {
    this.setState({fileWaze:e.target.files[0]});
  }

  onFormSubmitFacebook = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file',this.state.fileFacebook);
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'admin/template/icon?id='+this.state.templateId+'&btn=facebook',formData,config)
          .then((response) => {
            // window.location.reload();
            this.getTemplate();
          }).catch((error) => {
      });
    }
  }
  onChangeFacebook = (e) => {
    this.setState({fileFacebook:e.target.files[0]});
  }

  onFormSubmitInstagram = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file',this.state.fileInstagram);
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'admin/template/icon?id='+this.state.templateId+'&btn=instagram',formData,config)
          .then((response) => {
            // window.location.reload();
            this.getTemplate();
          }).catch((error) => {
      });
    }
  }
  onChangeInstagram = (e) => {
    this.setState({fileInstagram:e.target.files[0]});
  }

  onChange = (e) => {
    this.setState({file:e.target.files[0]});
}
  onChangeBackgroundImage = (e) => {
    this.setState({fileBackgroundImage:e.target.files[0]});
}
  onChangeDefaultCover = (e) => {
    this.setState({fileDefaultCover:e.target.files[0]});
}

handleHaveBckgroundImage = () => {
  if (this.state.haveBackgroundImage) {
    this.setState({haveBackgroundImage: false});
  } else {
    this.setState({haveBackgroundImage: true});
  }
}

  render() {
    return (
      <div>
        <Header />

        <Grid container spacing={2}>
          <Grid item xs={12}>
          <div style={{textAlign: 'right', marginRight: '5%'}}>
            <p style={{direction: 'rtl', fontSize: '2em', marginBottom: 0}} className="headerColor">עריכת תבנית</p>
          </div>
          </Grid>

          <Grid item xs={6} style={{textAlign:'right'}}>
            <div>
              <p style={{fontSize: '1.3em'}}><span style={{color: 'red'}}>*</span> תמונת הרקע</p>
              {(this.state.backgroundImage !== '' && this.state.backgroundImage !== undefined) && (
                <div>
                  {this.state.backgroundImage.includes('http') ? (
                    <img src={this.state.backgroundImage} style={{width: 200, height: 400}} />
                  ) : (
                    <img src={'https://app.dibit.co.il/templates/' + this.state.backgroundImage} style={{width: 200, height: 400}} />
                  )}
                </div>
              )}
              <form onSubmit={this.onFormSubmitBackgroundImage}>
                <br />
                <div style={{textAlign: 'right'}}>
                <input type="file" name="myImage" onChange={this.onChangeBackgroundImage} accept="image/*"/>
                <br /><br />
                <button type="submit" style={{fontSize: '1.2em'}}>העלאת התמונה</button>
                </div>
              </form>
            </div>
          </Grid>
          <Grid item xs={6} style={{textAlign:'right'}}>
            <div style={{marginRight: '10%'}}>
              <p style={{fontSize: '1.3em'}}><span style={{color: 'red'}}>*</span> תמונת התבנית</p>
              {(this.state.coverImage !== '' && this.state.coverImage !== undefined) && (
                <div>
                  {this.state.coverImage.includes('http') ? (
                    <img src={this.state.coverImage} style={{width: 200, height: 400}} />
                  ) : (
                    <img src={'https://app.dibit.co.il/templates/' + this.state.coverImage} style={{width: 200, height: 400}} />
                  )}
                </div>
              )}
              <form onSubmit={this.onFormSubmit}>
                <br />
                <div style={{textAlign: 'right'}}>
                <input type="file" name="myImage" onChange={this.onChange} accept="image/*"/>
                <br /><br />
                <button type="submit" style={{fontSize: '1.2em'}}>העלאת התמונה</button>
                </div>
              </form>
            </div>
          </Grid>
          <Grid item xs={12} style={{textAlign:'right'}}>
            <div style={{marginRight: '5%'}}>
              <p style={{fontSize: '1.3em'}}><span style={{color: 'red'}}>*</span> תמונת קאבר דיפולטיבית</p>
              {(this.state.defaultCover !== '' && this.state.defaultCover !== undefined) && (
                <div>
                  {this.state.defaultCover.includes('http') ? (
                    <img src={this.state.defaultCover} style={{width: 300, height: 200}} />
                  ) : (
                    <img src={'https://app.dibit.co.il/templates/' + this.state.defaultCover} style={{width: 300, height: 200}} />
                  )}
                </div>
              )}
              <form onSubmit={this.onFormSubmitDefaultCover}>
                <br />
                <div style={{textAlign: 'right'}}>
                <input type="file" name="myImage" onChange={this.onChangeDefaultCover} accept="image/*"/>
                <br /><br />
                <button type="submit" style={{fontSize: '1.2em'}}>העלאת התמונה</button>
                </div>
              </form>
            </div>


            <div style={{marginRight: '5%'}}>
            <br />
            יש תמונת רקע (לא לא - ייבחר הצבע)
            <Checkbox
              checked={this.state.haveBackgroundImage}
              onChange={(e) => this.handleHaveBckgroundImage()}
            />
            <br />
            </div>
          </Grid>

          <Grid item xs={12}>
          <div style={{textAlign: 'right', marginRight: '5%'}}>
              <p style={{fontSize: '1.3em'}}><span style={{color: 'red'}}>*</span> סט אייקונים</p>
              <Select
                value={this.state.iconssetId}
                style={{
                  direction: 'rtl',
                  textAlign: 'right',
                  width: '70%',
                  fontSize: '1.3em'
                }}
                inputProps={{
                  style: {
                    fontSize: '1.3em',
                    direction: 'rtl',
                    textAlign: 'right',
                  }
                }}
                onChange={(e) => this.setState({iconssetId: e.target.value})}
              >
                {this.state.iconssets.map((iconsset) => {
                  return (
                    <MenuItem value={iconsset._id} style={{direction: 'rtl', textAlign: 'right', fontSize: '1.3em'}}>{iconsset.color}</MenuItem>
                  );
                })}
              </Select>

              <p style={{fontSize: '1.3em', marginBottom: 0}}>קטגוריה</p>
              <p style={{fontSize: '1em', marginBottom: 0}}>{this.state.category}</p>
              <Select
                value={this.state.category}
                style={{
                  direction: 'rtl',
                  textAlign: 'right',
                  width: '70%',
                  fontSize: '1.3em'
                }}
                inputProps={{
                  style: {
                    fontSize: '1.3em',
                    direction: 'rtl',
                    textAlign: 'right',
                  }
                }}
                onChange={(e) => this.setState({category: e.target.value})}
              >
                {this.state.categories.reverse().map((category, i) => {
                  return (
                    <MenuItem value={category.name} style={{direction: 'rtl', textAlign: 'right', fontSize: '1.3em'}}>{category.name}</MenuItem>
                  );
                })}
              </Select>


              <p style={{fontSize:'1.3em'}}><span style={{color: 'red'}}>*</span> שם התבנית</p>
              <TextField
                value={this.state.name}
                onChange={(e) => this.setState({name: e.target.value})}
                placeholder="שם התבנית"
                style={{
                  direction: 'rtl',
                  textAlign: 'right',
                  width: '70%',
                  fontSize: '1.3em'
                }}
                inputProps={{
                  style: {
                    fontSize: '1.3em',
                    direction: 'rtl',
                    textAlign: 'right',
                  }
                }}
              />

              <p style={{fontSize: '1.3em'}}>תיאור התבנית</p>
              <TextField
                value={this.state.description}
                onChange={(e) => this.setState({description: e.target.value})}
                placeholder="תיאור התבנית"
                style={{
                  direction: 'rtl',
                  textAlign: 'right',
                  width: '70%',
                  fontSize: '1.3em'
                }}
                inputProps={{
                  style: {
                    fontSize: '1.3em',
                    direction: 'rtl',
                    textAlign: 'right',
                  }
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div style={{textAlign: 'right', marginRight: '5%'}}>
              <p style={{fontSize: '1.3em'}}><span style={{color: 'red'}}>*</span> סוג הכפתורים</p>
              <Select
                value={this.state.buttonsType}
                style={{
                  direction: 'rtl',
                  textAlign: 'right',
                  width: '70%',
                  fontSize: '1.3em'
                }}
                inputProps={{
                  style: {
                    fontSize: '1.3em',
                    direction: 'rtl',
                    textAlign: 'right',
                  }
                }}
                onChange={(e) => this.setState({buttonsType: e.target.value})}
              >
                <MenuItem value={'round'} style={{direction: 'rtl', textAlign: 'right', fontSize: '1.3em'}}>עגול</MenuItem>
                <MenuItem value={'square'} style={{direction: 'rtl', textAlign: 'right', fontSize: '1.3em'}}>מרובע</MenuItem>
                <MenuItem value={'ghost'} style={{direction: 'rtl', textAlign: 'right', fontSize: '1.3em'}}>ללא צורה</MenuItem>
              </Select>

              <p style={{fontSize: '1.3em'}}> צורת שבירה לתמונת רקע (קאבר)</p>
              <Select
                value={this.state.coverShape}
                style={{
                  direction: 'rtl',
                  textAlign: 'center',
                  width: '70%'
                }}
                onChange={(e) => this.setState({coverShape: e.target.value})}
              >
                <MenuItem value={'side'}>קו נשבר על הצד</MenuItem>
                <MenuItem value={'waves'}>גלים</MenuItem>
              </Select>

              <p style={{fontSize: '1.3em'}}><span style={{color: 'red'}}>*</span> פונט</p>
              <Select
                value={this.state.font}
                style={{
                  direction: 'rtl',
                  textAlign: 'center',
                  width: '70%'
                }}
                onChange={(e) => this.setState({font: e.target.value})}
              >
                <MenuItem value={'Rubik'}>Rubik</MenuItem>
                <MenuItem value={'Heebo'}>Heebo</MenuItem>
                <MenuItem value={'Arimo'}>Arimo</MenuItem>
                <MenuItem value={'Secular'}>Secular</MenuItem>

              </Select>
              <div>
              {this.state.font !== '' && (
                <p style={{direction: 'rtl', fontSize: '1.2em'}}>דוגמה:</p>
              )}

              {this.state.font === '' && (<h2 style={{direction: 'rtl', fontSize: '1.2em'}}>אז הגיע הלילה של כוכב השביט הראשון.</h2>)}
              {this.state.font === 'Rubik' && (<h2 className={'Rubik'} style={{direction: 'rtl', fontSize: '1.2em'}}>אז הגיע הלילה של כוכב השביט הראשון.</h2>)}
              {this.state.font === 'Heebo' && (<h2 className={'Heebo'} style={{direction: 'rtl', fontSize: '1.2em'}}>אז הגיע הלילה של כוכב השביט הראשון.</h2>)}
              {this.state.font === 'Arimo' && (<h2 className={'Arimo'} style={{direction: 'rtl', fontSize: '1.2em'}}>אז הגיע הלילה של כוכב השביט הראשון.</h2>)}
              {this.state.font === 'Secular' && (<h2 className={'Secular'} style={{direction: 'rtl', fontSize: '1.2em'}}>אז הגיע הלילה של כוכב השביט הראשון.</h2>)}
              {this.state.font === 'Amatic Sc' && (<h2 className={'AmaticSc'} style={{direction: 'rtl', fontSize: '1.2em'}}>אז הגיע הלילה של כוכב השביט הראשון.</h2>)}
              </div>

              {/*<p><span style={{color: 'red'}}>*</span> האם לאפשר העלאת תמונת רקע</p>
              <p style={{fontSize: '.7em', color: '#717171'}}>אם אופציה זאת לא מאופשרת, צבע הרקע שתבחר יהיה הצבע ברקע</p>
               <Switch
                checked={this.state.canUploadBackgroundImage}
                onChange={(e) => this.changeCanUploadBackgroundImage()}
                name="checkedA"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              /> אפשר*/}
            </div>
          </Grid>

          {/*<Grid item xs={6} style={{textAlign: 'right', direction: 'rtl'}}>
            <div style={{marginRight: '0%'}}>
            <p style={{fontSize: '1.3em'}}> העלאת כפתור ווטצאפ</p>
            {(this.state.template.whatsappIcon !== '' && this.state.template.whatsappIcon !== null && this.state.template.whatsappIcon !== undefined) && (
              <img src={'https://app.dibit.co.il/templates/'+this.state.template.whatsappIcon} style={{width: 55, height: 55}} />
            )}
            <form onSubmit={this.onFormSubmitWhatsapp}>
              <br />
              <div style={{textAlign: 'right'}}>
              <input type="file" name="myImage" onChange={this.onChangeWhatsapp} accept="image/*"/>
              <br /><br />
              <button type="submit" style={{fontSize: '1.2em'}}>העלאת התמונה</button>
              </div>
            </form>
            </div>
          </Grid>

          <Grid item xs={6} style={{textAlign: 'right', direction: 'rtl'}}>
          <div style={{marginRight: '10%'}}>
          <p style={{fontSize: '1.3em'}}> העלאת כפתור פייסבוק</p>
          {(this.state.template.facebookIcon !== '' && this.state.template.facebookIcon !== null && this.state.template.facebookIcon !== undefined) && (
            <img src={'https://app.dibit.co.il/templates/'+this.state.template.facebookIcon} style={{width: 55, height: 55}} />
          )}
          <form onSubmit={this.onFormSubmitFacebook}>
            <br />
            <div style={{textAlign: 'right'}}>
            <input type="file" name="myImage" onChange={this.onChangeFacebook} accept="image/*"/>
            <br /><br />
            <button type="submit" style={{fontSize: '1.2em'}}>העלאת התמונה</button>
            </div>
          </form>
          </div>
          </Grid>

          <Grid item xs={6} style={{textAlign: 'right', direction: 'rtl'}}>
          <div style={{marginRight: '0%'}}>
          <p style={{fontSize: '1.3em'}}> העלאת כפתור אינסטגרם</p>
          {(this.state.template.instagramIcon !== '' && this.state.template.instagramIcon !== null && this.state.template.instagramIcon !== undefined) && (
            <img src={'https://app.dibit.co.il/templates/'+this.state.template.instagramIcon} style={{width: 55, height: 55}} />
          )}
          <form onSubmit={this.onFormSubmitInstagram}>
            <br />
            <div style={{textAlign: 'right'}}>
            <input type="file" name="myImage" onChange={this.onChangeInstagram} accept="image/*"/>
            <br /><br />
            <button type="submit" style={{fontSize: '1.2em'}}>העלאת התמונה</button>
            </div>
          </form>
          </div>
          </Grid>

          <Grid item xs={6} style={{textAlign: 'right', direction: 'rtl'}}>
          <div style={{marginRight: '10%'}}>
            <p style={{fontSize: '1.3em'}}> העלאת כפתור וויז</p>
            {(this.state.template.wazeIcon !== '' && this.state.template.wazeIcon !== null && this.state.template.wazeIcon !== undefined) && (
              <img src={'https://app.dibit.co.il/templates/'+this.state.template.wazeIcon} style={{width: 55, height: 55}} />
            )}
            <form onSubmit={this.onFormSubmitWaze}>
              <br />
              <div style={{textAlign: 'right'}}>
              <input type="file" name="myImage" onChange={this.onChangeWaze} accept="image/*"/>
              <br /><br />
              <button type="submit" style={{fontSize: '1.2em'}}>העלאת התמונה</button>
              </div>
            </form>
          </div>
          </Grid>

          <Grid item xs={6} style={{textAlign: 'right', direction: 'rtl'}}>
          <p style={{fontSize: '1.3em'}}> העלאת כפתור אייקון לאתר</p>
          {(this.state.template.websiteIcon !== '' && this.state.template.websiteIcon !== null && this.state.template.websiteIcon !== undefined) && (
            <img src={'https://app.dibit.co.il/templates/'+this.state.template.websiteIcon} style={{width: 55, height: 55}} />
          )}
          <form onSubmit={this.onFormSubmitWebsite}>
            <br />
            <div style={{textAlign: 'right'}}>
            <input type="file" name="myImage" onChange={this.onChangeWebsite} accept="image/*"/>
            <br /><br />
            <button type="submit" style={{fontSize: '1.2em'}}>העלאת התמונה</button>
            </div>
          </form>
          </Grid>

          <Grid item xs={6} style={{textAlign: 'right', direction: 'rtl'}}>
          <div style={{marginRight: '10%'}}>
          <p style={{fontSize: '1.3em'}}> העלאת כפתור יוטיוב</p>
          {(this.state.template.youtubeIcon !== '' && this.state.template.youtubeIcon !== null && this.state.template.youtubeIcon !== undefined) && (
            <img src={'https://app.dibit.co.il/templates/'+this.state.template.youtubeIcon} style={{width: 55, height: 55}} />
          )}
          <form onSubmit={this.onFormSubmitYoutube}>
            <br />
            <div style={{textAlign: 'right'}}>
            <input type="file" name="myImage" onChange={this.onChangeYoutube} accept="image/*"/>
            <br /><br />
            <button type="submit" style={{fontSize: '1.2em'}}>העלאת התמונה</button>
            </div>
          </form>
          </div>
          </Grid>

          <Grid item xs={6} style={{textAlign: 'right', direction: 'rtl'}}>
          <p style={{fontSize: '1.3em'}}> העלאת כפתור "התקשרו אליי"</p>
          {(this.state.template.callmeIcon !== '' && this.state.template.callmeIcon !== null && this.state.template.callmeIcon !== undefined) && (
            <img src={'https://app.dibit.co.il/templates/'+this.state.template.callmeIcon} style={{width: 55, height: 55}} />
          )}
          <form onSubmit={this.onFormSubmitCallme}>
            <br />
            <div style={{textAlign: 'right'}}>
            <input type="file" name="myImage" onChange={this.onChangeCallme} accept="image/*"/>
            <br /><br />
            <button type="submit" style={{fontSize: '1.2em'}}>העלאת התמונה</button>
            </div>
          </form>
          </Grid>

          <Grid item xs={6} style={{textAlign: 'right', direction: 'rtl'}}>
          <div style={{marginRight: '10%'}}>
          <p style={{fontSize: '1.3em'}}> העלאת כפתור אייקון לתיק עבודות</p>
          {(this.state.template.portfolioIcon !== '' && this.state.template.portfolioIcon !== null && this.state.template.portfolioIcon !== undefined) && (
            <img src={'https://app.dibit.co.il/templates/'+this.state.template.portfolioIcon} style={{width: 55, height: 55}} />
          )}
          <form onSubmit={this.onFormSubmitPortfolio}>
            <br />
            <div style={{textAlign: 'right'}}>
            <input type="file" name="myImage" onChange={this.onChangePortfolio} accept="image/*"/>
            <br /><br />
            <button type="submit" style={{fontSize: '1.2em'}}>העלאת התמונה</button>
            </div>
          </form>
          </div>
          </Grid>*/}

          <Grid item xs={6}>
              <p style={{textAlign: 'right', marginRight: '0%', fontSize: '1.3em'}}>צבע הפונט הכללי</p>
              <div style={{float: 'right'}}>
                  <ChromePicker
                    color={ this.state.fontColor }
                    onChangeComplete={ (color) => this.handleChangeComplete(color, 'fontColor') }
                  />
              </div>
          </Grid>
          <Grid item xs={6}>
              <p style={{textAlign: 'right', marginRight: '10%', fontSize: '1.3em'}}>צבע הרקע</p>
              <div style={{float: 'right', marginRight: '10%'}}>
                  <ChromePicker
                    color={ this.state.backgroundColor }
                    onChangeComplete={ (color) => this.handleChangeComplete(color, 'backgroundColor') }
                  />
              </div>
          </Grid>
          <Grid item xs={6}>
              <p style={{textAlign: 'right', marginRight: '0%', fontSize: '1.3em'}}>צבע הכפתורים (רקע)</p>
              <div style={{float: 'right'}}>
                  <ChromePicker
                    color={ this.state.buttonsColor }
                    onChangeComplete={ (color) => this.handleChangeComplete(color, 'buttonsColor') }
                  />
              </div>
          </Grid>
          <Grid item xs={6}>
              <p style={{textAlign: 'right', marginRight: '10%', fontSize: '1.3em'}}>צבע הכפתורים (טקסט)</p>
              <div style={{float: 'right', marginRight: '10%'}}>
                  <ChromePicker
                    color={ this.state.buttonsTextColor }
                    onChangeComplete={ (color) => this.handleChangeComplete(color, 'buttonsTextColor') }
                  />
              </div>
          </Grid>
          <Grid item xs={6}>
              <p style={{textAlign: 'right', marginRight: '0%', fontSize: '1.3em'}}>צבע כותרת ראשית</p>
              <div style={{float: 'right'}}>
                  <ChromePicker
                    color={ this.state.titleColor }
                    onChangeComplete={ (color) => this.handleChangeComplete(color, 'titleColor') }
                  />
              </div>
          </Grid>
          <Grid item xs={6}>
              <p style={{textAlign: 'right', marginRight: '10%', fontSize: '1.3em'}}>צבע כותרת משנית</p>
              <div style={{float: 'right', marginRight: '10%'}}>
                  <ChromePicker
                    color={ this.state.secondaryTitleColor }
                    onChangeComplete={ (color) => this.handleChangeComplete(color, 'secondaryTitleColor') }
                  />
              </div>
          </Grid>
          <Grid item xs={6}>
            <p style={{textAlign: 'right', marginRight: '10%', fontSize: '1.3em'}}>צבע הטקסט</p>
            <div style={{float: 'right', marginRight: '10%'}}>
                <ChromePicker
                  color={ this.state.textColor }
                  onChangeComplete={ (color) => this.handleChangeComplete(color, 'textColor') }
                />
            </div>
          </Grid>
          <Grid item xs={6}>
            <p style={{textAlign: 'right', marginRight: '10%', fontSize: '1.3em'}}>צבע תפריט תחתון - כפתור ימני ושמאלי</p>
            <div style={{float: 'right', marginRight: '10%'}}>
                <ChromePicker
                  color={ this.state.bottomButtonSidesColor }
                  onChangeComplete={ (color) => this.handleChangeComplete(color, 'bottomButtonSidesColor') }
                />
            </div>
          </Grid>
          <Grid item xs={6}>
            <p style={{textAlign: 'right', marginRight: '10%', fontSize: '1.3em'}}>צבע תפריט תחתון - כפתור אמצעי</p>
            <div style={{float: 'right', marginRight: '10%'}}>
                <ChromePicker
                  color={ this.state.bottomButtonCenterColor }
                  onChangeComplete={ (color) => this.handleChangeComplete(color, 'bottomButtonCenterColor') }
                />
            </div>
          </Grid>
          <Grid item xs={6}>
            <p style={{textAlign: 'right', marginRight: '10%', fontSize: '1.3em'}}>צבע תפריט תחתון - צבע הטקסט</p>
            <div style={{float: 'right', marginRight: '10%'}}>
                <ChromePicker
                  color={ this.state.bottomButtonTextColor }
                  onChangeComplete={ (color) => this.handleChangeComplete(color, 'bottomButtonTextColor') }
                />
            </div>
          </Grid>

          <Grid item xs={6} style={{textAlign: 'right', direction: 'rtl'}}>
            <p style={{fontSize: '1.3em'}}>כפתור 2</p>
            <Select
              value={this.state.button2}
              style={{
                direction: 'rtl',
                textAlign: 'right',
                width: '70%',
                fontSize: '1.3em'
              }}
              inputProps={{
                style: {
                  fontSize: '1.3em',
                  direction: 'rtl',
                  textAlign: 'right',
                }
              }}
              onChange={(e) => this.setState({button2: e.target.value})}
            >
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={''}>לא מוגדר</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'facebook'}>פייסבוק</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'instagram'}>אינסטגרם</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'website'}>אתר</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'youtube'}>יוטיוב</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'callme'}>התקשר</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'whatsapp'}>ווטצאפ</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'shop'}>חנות</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'portfolio'}>תיק עבודות</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'waze'}>וויז</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={6} style={{textAlign: 'right', direction: 'rtl'}}>
            <div style={{marginRight: '10%'}}>
            <p style={{fontSize: '1.3em'}}>כפתור 1</p>
            <Select
              value={this.state.button1}
              style={{
                direction: 'rtl',
                textAlign: 'right',
                width: '70%',
                fontSize: '1.3em'
              }}
              inputProps={{
                style: {
                  fontSize: '1.3em',
                  direction: 'rtl',
                  textAlign: 'right',
                }
              }}
              onChange={(e) => this.setState({button1: e.target.value})}
            >
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={''}>לא מוגדר</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'facebook'}>פייסבוק</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'instagram'}>אינסטגרם</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'website'}>אתר</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'youtube'}>יוטיוב</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'callme'}>התקשר</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'whatsapp'}>ווטצאפ</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'shop'}>חנות</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'portfolio'}>תיק עבודות</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'waze'}>וויז</MenuItem>
            </Select>
            </div>
          </Grid>
          <Grid item xs={6} style={{textAlign: 'right', direction: 'rtl'}}>
            <p style={{fontSize: '1.3em'}}>כפתור 4</p>
            <Select
              value={this.state.button4}
              style={{
                direction: 'rtl',
                textAlign: 'right',
                width: '70%',
                fontSize: '1.3em'
              }}
              inputProps={{
                style: {
                  fontSize: '1.3em',
                  direction: 'rtl',
                  textAlign: 'right',
                }
              }}
              onChange={(e) => this.setState({button4: e.target.value})}
            >
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={''}>לא מוגדר</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'facebook'}>פייסבוק</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'instagram'}>אינסטגרם</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'website'}>אתר</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'youtube'}>יוטיוב</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'callme'}>התקשר</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'whatsapp'}>ווטצאפ</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'shop'}>חנות</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'portfolio'}>תיק עבודות</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'waze'}>וויז</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={6} style={{textAlign: 'right', direction: 'rtl'}}>
            <div style={{marginRight: '10%'}}>
            <p style={{fontSize: '1.3em'}}>כפתור 3</p>
            <Select
              value={this.state.button3}
              style={{
                direction: 'rtl',
                textAlign: 'right',
                width: '70%',
                fontSize: '1.3em'
              }}
              inputProps={{
                style: {
                  fontSize: '1.3em',
                  direction: 'rtl',
                  textAlign: 'right',
                }
              }}
              onChange={(e) => this.setState({button3: e.target.value})}
            >
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={''}>לא מוגדר</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'facebook'}>פייסבוק</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'instagram'}>אינסטגרם</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'website'}>אתר</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'youtube'}>יוטיוב</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'callme'}>התקשר</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'whatsapp'}>ווטצאפ</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'shop'}>חנות</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'portfolio'}>תיק עבודות</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'waze'}>וויז</MenuItem>
            </Select>
            </div>
          </Grid>
          <Grid item xs={6} style={{textAlign: 'right', direction: 'rtl'}}>
            <p style={{fontSize: '1.3em'}}>כפתור 6</p>
            <Select
              value={this.state.button6}
              style={{
                direction: 'rtl',
                textAlign: 'right',
                width: '70%',
                fontSize: '1.3em'
              }}
              inputProps={{
                style: {
                  fontSize: '1.3em',
                  direction: 'rtl',
                  textAlign: 'right',
                }
              }}
              onChange={(e) => this.setState({button6: e.target.value})}
            >
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={''}>לא מוגדר</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'facebook'}>פייסבוק</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'instagram'}>אינסטגרם</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'website'}>אתר</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'youtube'}>יוטיוב</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'callme'}>התקשר</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'whatsapp'}>ווטצאפ</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'shop'}>חנות</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'portfolio'}>תיק עבודות</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'waze'}>וויז</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={6} style={{textAlign: 'right', direction: 'rtl'}}>
            <div style={{marginRight: '10%'}}>
            <p style={{fontSize: '1.3em'}}>כפתור 5</p>
            <Select
              value={this.state.button5}
              style={{
                direction: 'rtl',
                textAlign: 'right',
                width: '70%',
                fontSize: '1.3em'
              }}
              inputProps={{
                style: {
                  fontSize: '1.3em',
                  direction: 'rtl',
                  textAlign: 'right',
                }
              }}
              onChange={(e) => this.setState({button5: e.target.value})}
            >
                <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={''}>לא מוגדר</MenuItem>
                <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'facebook'}>פייסבוק</MenuItem>
                <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'instagram'}>אינסטגרם</MenuItem>
                <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'website'}>אתר</MenuItem>
                <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'youtube'}>יוטיוב</MenuItem>
                <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'callme'}>התקשר</MenuItem>
                <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'whatsapp'}>ווטצאפ</MenuItem>
                <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'shop'}>חנות</MenuItem>
                <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'portfolio'}>תיק עבודות</MenuItem>
                <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'waze'}>וויז</MenuItem>
            </Select>
            </div>
          </Grid>
          <Grid item xs={6} style={{textAlign: 'right', direction: 'rtl'}}>
            <p style={{fontSize: '1.3em'}}>כפתור 8</p>
            <Select
              value={this.state.button8}
              style={{
                direction: 'rtl',
                textAlign: 'right',
                width: '70%',
                fontSize: '1.3em'
              }}
              inputProps={{
                style: {
                  fontSize: '1.3em',
                  direction: 'rtl',
                  textAlign: 'right',
                }
              }}
              onChange={(e) => this.setState({button8: e.target.value})}
            >
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={''}>לא מוגדר</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'facebook'}>פייסבוק</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'instagram'}>אינסטגרם</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'website'}>אתר</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'youtube'}>יוטיוב</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'callme'}>התקשר</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'whatsapp'}>ווטצאפ</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'shop'}>חנות</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'portfolio'}>תיק עבודות</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'waze'}>וויז</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={6} style={{textAlign: 'right', direction: 'rtl'}}>
            <div style={{marginRight: '10%'}}>
            <p style={{fontSize: '1.3em'}}>כפתור 7</p>
            <Select
              value={this.state.button7}
              style={{
                direction: 'rtl',
                textAlign: 'right',
                width: '70%',
                fontSize: '1.3em'
              }}
              inputProps={{
                style: {
                  fontSize: '1.3em',
                  direction: 'rtl',
                  textAlign: 'right',
                }
              }}
              onChange={(e) => this.setState({button7: e.target.value})}
            >
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={''}>לא מוגדר</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'facebook'}>פייסבוק</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'instagram'}>אינסטגרם</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'website'}>אתר</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'youtube'}>יוטיוב</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'callme'}>התקשר</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'whatsapp'}>ווטצאפ</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'shop'}>חנות</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'portfolio'}>תיק עבודות</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'waze'}>וויז</MenuItem>
            </Select>
            </div>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'right', direction: 'rtl'}}>
            <div style={{marginRight: '5%'}}>
            <p style={{fontSize: '1.3em'}}>כפתור 9</p>
            <Select
              value={this.state.button9}
              style={{
                direction: 'rtl',
                textAlign: 'right',
                width: '80%',
                fontSize: '1.3em'
              }}
              inputProps={{
                style: {
                  fontSize: '1.3em',
                  direction: 'rtl',
                  textAlign: 'right',
                }
              }}
              onChange={(e) => this.setState({button9: e.target.value})}
            >
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={''}>לא מוגדר</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'facebook'}>פייסבוק</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'instagram'}>אינסטגרם</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'website'}>אתר</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'youtube'}>יוטיוב</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'callme'}>התקשר</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'whatsapp'}>ווטצאפ</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'shop'}>חנות</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'portfolio'}>תיק עבודות</MenuItem>
              <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.3em'}} value={'waze'}>וויז</MenuItem>
            </Select>
            </div>
          </Grid>

          <Grid item xs={12} style={{width: '100%', textAlign: 'center'}}>
            <div style={{textAlign: 'center', width: '100%', marginLeft: '5%'}}>
              <Button onClick={(e) => this.updateTemplate()} style={{fontSize: '1.5em', color: 'white', width: '70%'}} className="addButton">
                אישור ושמירת התבנית
              </Button>
              <br /><br />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default EditTemplate;
