import React, { Component } from 'react';
import logo from '../logo.svg';
import '../App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { SliderPicker, ChromePicker } from 'react-color'

import Switch from '@material-ui/core/Switch';

import Header from '../components/Header';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DeleteIcon from '@material-ui/icons/Delete';

import ImageUploader from 'react-images-upload';

import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const API = 'https://api.dibit.co.il/v1/';

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      categories: [],
    };
  }

  componentDidMount () {
    this.getData();
  }

  getData = () => {
    try {
      let token = cookies.get('__dato__');

      axios.get(API + 'admin/categories', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.setState({
            categories: response.data.data.categories
          });
        }
      }).catch(err => {
        console.log(err);
        alert('הייתה שגיאה בקבלת המידע, אנא נסה להתנתק ולהתחבר בשנית');
      });
    } catch (err) {
      console.log(err);
      alert('הייתה שגיאה בקבלת המידע, אנא נסה להתנתק ולהתחבר בשנית');
    }
  }

  deleteCategory = (id) => {
    try {
      let token = cookies.get('__dato__');

      axios.delete(API + 'admin/category?id='+id, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.getData();
        }
      }).catch(err => {
        console.log(err);
        alert('הייתה שגיאה בקבלת המידע, אנא נסה להתנתק ולהתחבר בשנית');
      });
    } catch (err) {
      console.log(err);
      alert('הייתה שגיאה בקבלת המידע, אנא נסה להתנתק ולהתחבר בשנית');
    }
  }

  addCategory = () => {
    try {
      let token = cookies.get('__dato__');

      axios.post(API + 'admin/category', {
        name: this.state.name
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.getData();
          this.setState({name: ''})
        }
      }).catch(err => {
        console.log(err);
        alert('הייתה שגיאה בקבלת המידע, אנא נסה להתנתק ולהתחבר בשנית');
      });
    } catch (err) {
      console.log(err);
      alert('הייתה שגיאה בקבלת המידע, אנא נסה להתנתק ולהתחבר בשנית');
    }
  }

  render() {
    return (
      <div>
        <Header />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div style={{textAlign: 'right', marginRight: '5%', marginTop: 0, marginBottom: 0}}>
              <p style={{direction: 'rtl', fontSize: '2em'}} className="headerColor">קטגוריות התבניות</p>

              <p style={{fontSize: '1.7em', textDecoration: 'underline'}}>יצירת קטגוריה חדשה</p>
              <div>
                  <p style={{fontSize: '1.3em'}}>קטגוריה</p>
                  <TextField
                    value={this.state.name}
                    onChange={(e) => this.setState({name: e.target.value})}
                    style={{
                      backgroundColor: 'white',
                      width: '50%',
                      color: 'black',
                      textAlign: 'right',
                      direction: 'rtl',
                      fontSize: '1.3em'
                    }}
                    inputProps={{
                      style:{
                        backgroundColor: 'white',
                        width: '100%',
                        color: 'black',
                        textAlign: 'right',
                        direction: 'rtl',
                        fontSize: '1.3em'
                      }
                    }}
                  />
                  <br /><br />

                  <Button onClick={(e) => this.addCategory()} type="submit" className="addButton" style={{border: '0px solid transparent', borderRadius: 10, padding: '0.5%', color: 'white'}}>יצירה</Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <p style={{textAlign: 'right', marginRight: '5%', fontSize: '1.7em', textDecoration: 'underline'}}>הגלריה של דיביט</p>
            <center>
            <TableContainer component={Paper} style={{maxWidth: '90%'}}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right" style={{fontSize: '1.2em'}}>מחיקה</TableCell>
                    <TableCell align="right" style={{fontSize: '1.2em'}}>קטגוריה</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.categories.reverse().map((index, i) => {
                    return (
                      <TableRow key={i.toString()+'_image'}>
                        <TableCell align="right" style={{fontSize: '1.2em'}}>
                          <IconButton onClick={(e) => this.deleteCategory(index._id)}>
                            <DeleteForeverIcon style={{width: 32, height: 32, color: 'red'}}  onClick={(e) => this.deleteCategory(index._id)}/>
                          </IconButton>
                        </TableCell>
                        <TableCell align="right" style={{fontSize: '1.2em'}}>
                          {index.name}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            </center>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Categories;
