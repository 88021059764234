import React, { Component } from 'react';
import logo from '../logo.svg';
import '../App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Card from '@material-ui/core/Card';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { SliderPicker, ChromePicker } from 'react-color'

import Switch from '@material-ui/core/Switch';

import OfflineHeader from '../components/OfflineHeader';

import axios from 'axios';
import Cookies from 'universal-cookie';

import IntroImage from '../resources/intro.png';

const cookies = new Cookies();
const API = 'https://api.dibit.co.il/v1/';

class AddPackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      loading: false,
      error: false
    };
  }

  login = () => {
    this.setState({loading: true});

    if (this.state.username !== '' && this.state.password !== '') {
      let payload = {
        'email': this.state.username,
        'password': this.state.password
      }

      axios.post(API + 'admin/login', payload).then(response => {
        if (response.data.status === "ok") {
          cookies.set('__dato__', response.data.data.token);
          window.location.reload();
        } else {
          this.setState({loading: false, error: true});
        }
      }).catch(err => {
        console.log(err);
        this.setState({loading: false, error: true});
      });
    } else {
      this.setState({loading: false, error: true});
    }
  }

  render() {
    return (
      <div style={{
        background: `url('`+IntroImage+`')`,
        height: '100vh',
        color: 'white'
      }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div style={{}}>
              <p style={{direction: 'rtl', fontSize: '2em', maxWidth: '90%'}}>התחברות - ניהול דיביט</p>
            </div>
          </Grid>
          <Grid item xs={12}>
            <center>
            <Card style={{maxWidth: '90%'}}>
              <div style={{padding: '2%', textAlign: 'right'}}>
                {this.state.error && (<p style={{color: 'red', direction: 'rtl', fontSize: '1.5em'}}>אופס.. שגיאה בהתחברות, אנא וודא שהשם משתמש והסיסמה נכונים!</p>)}
                <p style={{fontSize: '1.5em'}}>שם משתמש</p>
                <TextField
                  value={this.state.username}
                  onChange={(e) => this.setState({username: e.target.value})}
                  style={{
                    direction: 'rtl',
                    textAlign: 'right',
                    width: '70%',
                  }}
                  inputProps={{
                    style:{
                      fontSize: '1.5em'
                    }
                  }}
                  placeholder='שם משתמש'
                />

                <p style={{fontSize: '1.5em'}}>סיסמה</p>
                <TextField
                  value={this.state.password}
                  onChange={(e) => this.setState({password: e.target.value})}
                  style={{
                    direction: 'rtl',
                    textAlign: 'right',
                    width: '70%',
                  }}
                  inputProps={{
                    style:{
                      fontSize: '1.5em'
                    }
                  }}
                  type='password'
                  placeholder='סיסמה'
                />
                <br /><br />
                <div style={{textAlign: 'right'}}>
                  <Button onClick={(e) => this.login()} style={{borderRadius: 50, maxWidth: '70%', fontSize: '1.2em'}} variant='contained' color="primary">
                    התחברות
                  </Button>
                </div>
              </div>
            </Card>
            </center>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default AddPackage;
