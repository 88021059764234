import React, { Component } from 'react';
import logo from '../logo.svg';
import '../App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
class OfflineHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div>
        <AppBar className="appbar" position="static" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundImage: "linear-gradient(to right, #4880EC, #019CAD)"}}>
          <Toolbar>
            <Button style={{color: 'white', textTransform: 'none'}} onClick={(e) => window.location.href='/'}>
            <Typography variant="h6">
              Dibit פורטל ניהול
            </Typography>
            </Button>
          </Toolbar>

          <div style={{marginTop: '1.8%', marginRight: '5%'}}>

          </div>
        </AppBar>
        <Drawer
          variant="persistent"
          open={true}
        >
          <div>
            <p>drawer content</p>
          </div>
        </Drawer>
      </div>
    );
  }
}

export default OfflineHeader;
