import React, { Component } from 'react';
import logo from '../logo.svg';
import '../App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { SliderPicker, ChromePicker } from 'react-color'

import Switch from '@material-ui/core/Switch';

import Header from '../components/Header';

import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const API = 'https://api.dibit.co.il/v1/';


class AddPackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      description: '',
      price: 50,
      shareButtons: false,
      aboutBusiness: false,
      dedicatedURL: false,
      saveContacts: false,
      messages: false,
      editCard: false,
    };
  }

  addPackage = () => {
    if (this.state.name !== '' && this.state.description !== '') {
      let token = cookies.get('__dato__');

      let payload = {
        'name': this.state.name,
        'description': this.state.description,
        'price': this.state.price,
        'shareButtons': this.state.shareButtons,
        'aboutBusiness': this.state.aboutBusiness,
        'dedicatedURL': this.state.dedicatedURL,
        'saveContacts': this.state.saveContacts,
        'messages': this.state.messages,
        'editCard': this.state.editCard,
      }

      try {
        axios.post(API + 'admin/package', payload, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then(response => {
          if (response.data.status === "ok") {
            alert('החבילה נוצרה בהצלחה');
            window.location.href='/';
          } else {
            alert('חלה שגיאה, אנא נסה שוב');
          }
        }).catch(err => {
          console.log(err);
          alert('חלה שגיאה, אנא נסה שוב');
        });
      } catch (err) {
        console.log(err);
        alert('חלה שגיאה, אנא נסה שוב');
      }
    } else {
      alert('יש להשלים את כל השדות ליצירת חבילה');
    }
  }

      handleShareButtons = () => {
        let c = this.state.shareButtons;
        if (c) {
          this.setState({shareButtons: false});
        } else {
          this.setState({shareButtons: true});
        }
      }
      handleAboutBusiness = () => {
        let c = this.state.aboutBusiness;
        if (c) {
          this.setState({aboutBusiness: false});
        } else {
          this.setState({aboutBusiness: true});
        }
    }
    handleDedicatedURL = () => {
      let c = this.state.dedicatedURL;
      if (c) {
        this.setState({dedicatedURL: false});
      } else {
        this.setState({dedicatedURL: true});
      }
    }
    handleSaveContacts = () => {
      let c = this.state.saveContacts;
      if (c) {
        this.setState({saveContacts: false});
      } else {
        this.setState({saveContacts: true});
      }
    }
    handleMessages = () => {
      let c = this.state.messages;
      if (c) {
        this.setState({messages: false});
      } else {
        this.setState({messages: true});
      }
    }
    handleEditCard = () => {
      let c = this.state.editCard;
      if (c) {
        this.setState({editCard: false});
      } else {
        this.setState({editCard: true});
      }
    }

  render() {
    return (
      <div>
        <Header />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div style={{marginRight: '5%'}}>
              <h2 style={{direction: 'rtl'}}>הוספת חבילה</h2>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{textAlign: 'right', marginRight: '5%'}}>
              <p><span style={{color: 'red'}}>*</span> שם החבילה</p>
              <TextField
                value={this.state.name}
                onChange={(e) => this.setState({name: e.target.value})}
                placeholder="שם התבנית"
                style={{
                  direction: 'rtl',
                  textAlign: 'right',
                  width: '70%'
                }}
              />

              <p>תיאור החבילה</p>
              <TextField
                value={this.state.description}
                onChange={(e) => this.setState({description: e.target.value})}
                placeholder="שם התבנית"
                style={{
                  direction: 'rtl',
                  textAlign: 'right',
                  width: '70%'
                }}
              />

              <p><span style={{color: 'red'}}>*</span> מחיר חודשי</p>
              <Input
                value={this.state.price}
                onChange={(e) => this.setState({price: e.target.value})}
                style={{
                  direction: 'rtl',
                  textAlign: 'right',
                  width: '70%'
                }}
                type="number"
                startAdornment="₪ "
              />
              <br />
              <div style={{direction: 'rtl'}}>
              <span style={{direction: 'rtl', fontSize:'1.3em'}}>כפתורי שיתוף</span> <Checkbox
                checked={this.state.shareButtons}
                onChange={(e) => this.handleShareButtons()}
              />
              <br />
              <span style={{direction: 'rtl', fontSize:'1.3em'}}>אודות העסק</span><Checkbox
                checked={this.state.aboutBusiness}
                onChange={(e) => this.handleAboutBusiness()}
              />
                <br />
               <span style={{textAlign: 'right', direction: 'rtl', fontSize:'1.3em'}}>כתובת ייחודית</span><Checkbox
                checked={this.state.dedicatedURL}
                onChange={(e) => this.handleDedicatedURL()}
              />
                <br />
              <span style={{direction: 'rtl', fontSize:'1.3em'}}>שמירה באנשי קשר</span><Checkbox
                checked={this.state.saveContacts}
                onChange={(e) => this.handleSaveContacts()}
              />
                <br />
              <span style={{direction: 'rtl', fontSize:'1.3em'}}>שליחה וקבלת הודעה</span><Checkbox
                checked={this.state.messages}
                onChange={(e) => this.handleMessages()}
              />
                <br />
              <span style={{direction: 'rtl', fontSize:'1.3em'}}>עריכת תבנית</span><Checkbox
                checked={this.state.editCard}
                onChange={(e) => this.handleEditCard()}
              />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
          <div style={{textAlign: 'center', marginLeft: '25%'}}>
            <Button onClick={(e) => this.addPackage()} style={{maxWidth: '70%', fontSize: '1.2em'}} variant='contained' color="primary">
              אישור ושמירת החבילה
            </Button>
          </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default AddPackage;
