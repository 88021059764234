import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import {
 BrowserRouter as Router,
 Switch,
 Route,
 Link
} from "react-router-dom";


import EditIconsset from './pages/EditIconsset';
import AddIconsset from './pages/AddIconsset';
import EditClient from './pages/EditClient';
import Categories from './pages/Categories';
import Dashboard from './pages/Dashboard';
import Templates from './pages/Templates';
import Packages from './pages/Packages';
import Clients from './pages/Clients';
import AddTemplate from './pages/AddTemplate';
import EditTemplate from './pages/EditTemplate';
import AddPackage from './pages/AddPackage';
import EditPackage from './pages/EditPackage';
import Images from './pages/Images';
import Login from './pages/Login';
import Payments from './pages/Payments';

import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const API = 'https://api.dibit.co.il/v1/';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connected: false
    };
  }

  componentDidMount() {
    this.validate();
  }

  setConnectionStatus = (status = 'not_connected') => {
    if (status === 'connected') {
      this.setState({connected: true});
    } else {
      this.setState({connected: false})
    }
  }

  validate = () => {
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.get(API + 'admin/validate', {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          if (response.data.status === "ok") {
            this.setConnectionStatus('connected');
          } else {
            this.setConnectionStatus();
          }
        }).catch(err => {
          console.log(err);
          this.setConnectionStatus();
        });
      } catch (err) {
        console.log(err);
        this.setConnectionStatus();
      }
    }
  }

  render() {
    return (
      <div>
        {this.state.connected ? (
          <Router>
          <Switch>
            <Route exact path="/payments">
              <Payments />
            </Route>
            <Route exact path="/iconsset/update">
              <EditIconsset />
            </Route>
            <Route exact path="/iconsset/add">
              <AddIconsset />
            </Route>
            <Route exact path="/client/manage">
              <EditClient />
            </Route>
            <Route exact path="/categories">
              <Categories />
            </Route>
            <Route exact path="/images">
              <Images />
            </Route>
            <Route exact path="/packages">
              <Packages />
            </Route>
            <Route exact path="/templates">
              <Templates />
            </Route>
            <Route exact path="/clients">
              <Clients />
            </Route>
            <Route exact path="/package/add">
              <AddPackage />
            </Route>
            <Route exact path="/package/update">
              <EditPackage />
            </Route>
            <Route exact path="/template/update">
              <EditTemplate />
            </Route>
            <Route exact path="/template/add">
              <AddTemplate />
            </Route>
            <Route path="/">
              <Dashboard />
            </Route>
          </Switch>
          </Router>
        ) : (
          <Router>
          <Switch>
            <Route path="/">
              <Login />
            </Route>
          </Switch>
          </Router>
        )}
      </div>
    );
  }
}

export default App;
